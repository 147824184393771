import React, { useState, useEffect } from 'react';
import "./HomeDescription.css";
import {useMediaQuery} from "react-responsive";

const AnimatedText = ({ text }) => {
    const [letters, setLetters] = useState([]);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            if (typeof window !== 'undefined') {
                setScrollPosition(window.scrollY);
            }
        };
        if (typeof window !== 'undefined') {
            window.addEventListener("scroll", onScroll);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener("scroll", onScroll);
            }
        };
    }, []);

    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const scrollDetector = isDesktop ? 1300 : 400;

    useEffect(() => {
        if (scrollPosition >= scrollDetector) {
            const chars = text.split('').map((char, index) => ({
                char,
                key: `${char}-${index}`,
                style: {
                    animationDelay: `${index * 0.1}s`
                }
            }));
            setLetters(chars);
        }
    }, [scrollPosition, text]);


    return (
        <span className="acid">
            {letters.map(letter => (
                <span key={letter.key} style={letter.style}>
                    {letter.char}
                </span>
            ))}
        </span>
    );

};


export const HomeDescription = () => {
    const [rotation, setRotation] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [textVisible, setTextVisible] = useState(false);

    // Gestionnaire de scroll pour la rotation
    useEffect(() => {
        const onScroll = () => {
            setRotation(window.scrollY / 13);
            setScrollPosition(window.scrollY); // Met à jour la position du scroll
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const scrollDetector = isDesktop ? 1100 : 400;

    // Utilisez useEffect pour mettre à jour textVisible en fonction de scrollPosition
    useEffect(() => {
        setTextVisible(scrollPosition >= scrollDetector);
    }, [scrollPosition, scrollDetector]);

    return (
        <div className="pageInfos">
            <div className="container">
                <div className="background-image" style={{ transform: `rotate(${rotation}deg)` }}></div>

                <div className="infos">
                    <img className="elipse" src="./images/accueil/elipse.png" alt="logo_elipse"/>

                    <div className="text">
                        <span className={textVisible ? "animatedTextWhite" : "textWhite"}>
                        Boostez vos communications
                        Internes & externes grâce à
                        nos solutions phygitales.
                            </span>
                        <span className={textVisible ? "animatedTextGrey" : "textGrey"}>
                        Faites vivre à votre audience
                        des expériences immersives,
                        interactives, originales et
                        inédites, sur vos événements
                        et points de vente</span>
                        <span className="dot">.</span>

                    </div>

                    <div className="datas">
                        <div className="dataColumn">
                            <span className="white">incubé par</span>
                            <AnimatedText text="lvmh" />
                        </div>

                        <div className="dataColumn">
                            <span className="white">clients</span>
                            <AnimatedText text="+600" />
                        </div>

                        <div className="dataColumn">
                            <span className="white">projets</span>
                            <AnimatedText text="+2000" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};
