import React from 'react';
import "./Product.css";
import {ButtonSite} from "../../Button/ButtonSite";

export const Product = ({title1, title2, text, img, keyword, bg}) => {


    return (
        <div className="product">


            <h1 className="title">
                <span className="bai">{title1}</span>
                <span className="druk">{title2}</span>
            </h1>

            <div className="left">

                <div className="bg" style={{ backgroundImage: `url(${bg})` }} alt=""></div>
                <img className="img" src={img} alt="" />
            </div>

            <div className="right">
                <img className="carreacid" src="images/studio/carreacid.png" alt="studio-orbis" />
                <div className="text"> {text} </div>
                <div className="keyword"> <img className="batton" src="images/studio/batton.png" alt="studio-orbis" />{keyword}</div>
                <div className="productButton"> <ButtonSite lien="/contact" logo="images/button/lune.png" text="Nous contacter"/> <br /> </div>
            </div>

        </div>
    );
};
