import React, {useEffect} from 'react';
import "./ProductVideo.css";
import {ButtonSite} from "../../Button/ButtonSite";

export const ProductVideo = ({title1, title2, text, img, keyword, bg, video}) => {



    return (

        <div className="productVideo">


            <h1 className="title">
                <span className="bai">{title1}</span>
                <span className="druk">{title2}</span>
            </h1>

            <div className="left">

                <div className="bg" style={{ backgroundImage: `url(${bg})` }} alt=""></div>
                <video
                    className="img"
                    muted={true}
                    autoPlay="autoplay"
                    loop={true}
                    playsInline
                    style={{ pointerEvents: 'none' }}
                    controls={false}
                    preload="auto"
                >
                    <source src={video} type="video/mp4" />
                </video>
            </div>

            <div className="right">
                <img className="carreacid" src="images/studio/carreacid.png" alt="studio-orbis" />
                <div className="text"> {text} </div>
                <div className="keyword"> <img className="batton" src="images/studio/batton.png" alt="studio-orbis" />{keyword}</div>
                <div className="productButton"> <ButtonSite lien="/contact" logo="images/button/lune.png" text="Nous contacter"/> <br /> </div>
            </div>

        </div>
        /*
        <div className="productVideo">

            <h1 className="title">
                <span className="bai">{title1}</span>
                <span className="druk">{title2}</span>
            </h1>


            <video
                className="img"
                width="100%"
                height="100%"
                muted={true}
                autoPlay="autoplay"
                loop={true}
                playsInline
                style={{ pointerEvents: 'none' }}
                controls={false}
                preload="auto"
            >
                <source src={video} type="video/mp4" />
            </video>


            <img className="carreacid" src="images/studio/carreacid.png" alt="studio-orbis"/>

            <p className="text"> {text} <br/> <div className="mobileButton"> <ButtonSite lien="/contact" logo="images/button/lune.png" text="Nous contacter"/></div></p>

            <div className="keyword">

                <img className="batton" src="images/studio/batton.png" alt="studio-orbis"/>{keyword}
            </div>
            <div className="productButton">
                <ButtonSite lien="/contact" logo="images/button/lune.png" text="Nous contacter"/>
            </div>


        </div>*/
    );
};
