import React from 'react';
import "./HomeHeroVideo.css";

import {useMediaQuery} from "react-responsive";

export const HomeHeroVideo = () => {
    const backgroundImage = {
        backgroundImage: `url(images/accueil/home_hero.png)`
    };
    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const isMobileMedia= useMediaQuery({ query: '(min-width: 700px)' });
    const video = isMobileMedia ? "video/hero/CASESTUDIES.mp4": "video/hero/HOLOGRAMMES_MOBILE.mp4";
    const handleMouseEnter = () => {
        document.querySelector('.text1').classList.add('hovered');
        document.querySelector('.text2').classList.add('hovered');
        document.querySelector('.text3').classList.add('hovered');
        document.querySelector('.text4').classList.add('hovered');
    }
    const handleMouseLeave = () => {
        document.querySelector('.text1').classList.remove('hovered');
        document.querySelector('.text2').classList.remove('hovered');
        document.querySelector('.text3').classList.remove('hovered');
        document.querySelector('.text4').classList.remove('hovered');
    }

    return (
        <>
            <div className="HomeHeroVideo">
                <video className="videoBackground"
                       alt="HeroVideo"
                       autoPlay
                       muted
                       loop
                       playsInline
                       controls={false}
                       style={{pointerEvents: 'none'}}>
                    <source src={video} type="video/mp4"/>
                    Votre navigateur ne prend pas en charge les vidéos HTML5.
                </video>
                <div className="videoFade"></div>


                <div className="container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <h1 className="text1">we are</h1>
                    <h1 className="text2">the new</h1>
                    <h1 className="text3">experience</h1>
                    <h1 className="text4">makers</h1>
                </div>

                {isDesktop ? null :
                <div className="hello"> Say hello - <a className="tel" href="tel:0140921153"> 01 40 92 11 53</a>
                    <img
                        src="./images/accueil/stars.png" alt=""/>
                </div>}

            </div>


        </>

    );
};

