import React from 'react';
import "./HeroVideo.css";
import {useMediaQuery} from "react-responsive";

export const HeroVideo = ({videoSrc, titre1, titre2, description}) => {
    let interactiviteStyle = {};
    if (titre1 === "inter") {
        interactiviteStyle = {marginTop: '40px'};
    }

    return (
        <div className="HeroVideo">
            <video className="videoBackground"
                   alt="HeroVideo"
                   autoPlay
                   muted
                   loop
                   playsInline
                   controls={false}
                   style={{pointerEvents: 'none'}}>
                <source src={videoSrc} type="video/mp4"/>
                Votre navigateur ne prend pas en charge les vidéos HTML5.
            </video>
            <div className="videoFade"></div>


            <div className="container">
                <h1 className="titre" style={interactiviteStyle}>
                    <span className="fontBai">{titre1}</span>
                    {titre1 === "inter" ? null : <br/>}
                    <span className="fontDruk">{titre2}</span>
                </h1>
                <p className="description">
                    {
                        description === "instaorbis" ?
                            <div>
                                Retrouvez tous nos projets sur Instagram
                                <a href="https://www.instagram.com/orbisholographics/?hl=fr" target="_blank"> @orbisholographics</a>
                            </div>
                            :
                            <div >{description}</div>
                    }


                </p>
            </div>
        </div>
    );
};
