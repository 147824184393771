import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { HeroVideo } from "../../Section/HeroVideo/HeroVideo";
import { getHologrammeProduct } from "./GetHologrammeProduct/getHologrammeProduct";
import {Description} from "../../Section/Description/Description";
import {Product} from "../../Section/Product/Product";
import "./PageHologramme.css" ;
import {ButtonSite} from "../../Button/ButtonSite";
import {ProductVideo} from "../../Section/ProductVideo/ProductVideo";

export const PageHologramme = () => {
    const description = "Voici la page d'accueil d'orbis Holographique, une entreprise experte en hologramme, de la création à la mise en place du produit";

    const schema = {
        "@context": "http://schema.org",
        "@type": "Service",
        "name": "Hologramme",
        "description": description,
        "url": "https://orbis-prod.fr/hologrammes",
        "provider": {
            "@type": "Organization",
            "name": "Orbis Holographics"
        }
    };

    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });

    const video = isDesktop ? "video/hero/HOLOGRAMMES.mp4" : "video/hero/HOLOGRAMMES_MOBILE.mp4";
/**/
    const [currentIndex, setCurrentIndex] = useState(0);
    const products = getHologrammeProduct();
    const [animation, setAnimation] = useState('');

    const handleScroll = (event) => {
        console.log(`Scroll actuel de la page : ${window.scrollY}px`);
        if (window.scrollY > 800)
        {
            if (event.deltaY > 0) { // Scroll vers le bas
                setCurrentIndex(prevIndex => (prevIndex < products.length - 1 ? prevIndex + 1 : prevIndex));
            }
        }
        if (window.scrollY < 1900)
        {
            if (event.deltaY < 0) { // Scroll vers le bas
                setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
            }
        }
    };



    useEffect(() => {
        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    useEffect(() => {
        setAnimation('fadeOut'); // Commencez par disparaître
        const timeoutId = setTimeout(() => {
            setAnimation('fadeIn'); // Puis apparaissez avec le nouveau contenu
        }, 100); // le délai doit correspondre à la durée de l'animation de fadeOut

        return () => clearTimeout(timeoutId);
    }, [currentIndex]);


    const currentProduct = products[currentIndex];


    const HologrammeProductMobile = () => {
        return (
            <>
                <Description color1="white"  text1="Boostez vos communications internes & externes grâce à nos solutions phygitales. "
                             color2="grey" text2="Faites vivre à votre audience des expériences immersives, interactives, originales et inédites, sur vos événements et points de vente"
                             color3="acid" text3="."
                />
                <ProductVideo title2={products[0].title} text={products[0].text} keyword={products[0].keyword} video={products[0].video} />
                <ProductVideo title2={products[1].title} text={products[1].text} keyword={products[1].keyword} video={products[1].video} />
                <ProductVideo title2={products[2].title} text={products[2].text} keyword={products[2].keyword} video={products[2].video} />
                <ProductVideo title2={products[3].title} text={products[3].text} keyword={products[3].keyword} video={products[3].video} />
                <ProductVideo title2={products[4].title} text={products[4].text} keyword={products[4].keyword} video={products[4].video} />
                <ProductVideo title2={products[5].title} text={products[5].text} keyword={products[5].keyword} video={products[5].video} />
                <ProductVideo title2={products[6].title} text={products[6].text} keyword={products[6].keyword} video={products[6].video} />
                <ProductVideo title2={products[7].title} text={products[7].text} keyword={products[7].keyword} video={products[7].video} />
                <ProductVideo title2={products[8].title} text={products[8].text} keyword={products[8].keyword} video={products[8].video} />
            </>
        );
    }

    return (
        <div>
            <Helmet>
                <title>Hologramme</title>
                <meta name="hologramme" content={description} />
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>

            <HeroVideo videoSrc={video} titre1="nos" titre2="hologrammes" description="Plug&Play | Sur mesure"/>
            {isDesktop ?
            <div className="PageHologramme">
                <div className="product-display">

                    <div className="leftSelector">
                        {products.map((product, index) => (
                            <div
                                key={index}
                                className={`selector ${currentIndex === index ? "actif" : "inactif"}`}
                                onClick={() => setCurrentIndex(index)}
                            >
                                <div className="textSelector">{product.title}</div>
                            </div>
                        ))}
                    </div>



                    <div className="rightProduct">
                        <div className="text">
                            <h2 className={animation}>{currentProduct.title}</h2>
                            <img className={`carreacid`} src="images/studio/carreacid.png" alt="carreacid" />
                            <p className={`paragraphe ${animation}`}>{currentProduct.text}</p>
                            <p className={`keyword ${animation}`}>{currentProduct.keyword}</p>
                            <div className="button">
                                <ButtonSite lien="/contact" logo="images/button/lune.png" text="Nous contacter"/>
                            </div>
                        </div>
                        <div className={`media ${animation}`}>
                            {/*<img src={currentProduct.img} alt={currentProduct.title} />*/}

                            <video
                                key={currentProduct.video} // Ajout de l'attribut key
                                className="displayImage"
                                alt="arVidéo"
                                autoPlay
                                muted
                                loop
                                playsInline
                                controls={false}
                                style={{ pointerEvents: 'none' }}>
                                <source src={currentProduct.video || "video/accueil/ar/YOUVDEE.mp4"} type="video/mp4" />
                                Votre navigateur ne prend pas en charge les vidéos HTML5.
                            </video>

                        </div>
                    </div>

                </div>

            </div>
                : <HologrammeProductMobile /> }
        </div>
    );
};
