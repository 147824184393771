import React from 'react';
import "./ContactDatas.css";

export const ContactDatas = () => {



    const openLink = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className="ContactDatas">
            <h1 className="showroom">showroom</h1>
            <h2 className="adresse1">37 rue traversière, 92100 boulogne-billancourt  </h2>
           {/* <h2 className="adresse2">STATION F, 5 Parvis Alan Turing, 75013 Paris   </h2>*/}

            <h1 className="telephone">téléphone</h1>
            <a className="numero" /*href="tel:0140921153"*/> {/*01 40 92 11 53*/} Indisponible</a>

            <h1 className="mail">mail</h1>
            <a className="adresseMail" href="mailto:contact@orbis-prod.fr"> contact@orbis-prod.fr  </a>

            <h1 className="reseaux">réseaux</h1>
            <p className="logoReseaux">
                <img src="./images/button/instagramacid.png" onClick={() => openLink('https://www.instagram.com/orbisholographics/?hl=fr')} alt="insta"/>
                <img src="./images/button/linkedinacid.png" onClick={() => openLink('https://www.linkedin.com/company/orbisholographics/mycompany/')} alt="linkdin"/>
                <img src="./images/button/youtubeacid.png" onClick={() => openLink('https://www.youtube.com/channel/UCh3X9GbS32N4A42MNDO4TRQ')} alt="youtube"/>
                <img src="./images/button/facebookacid.png" onClick={() => openLink('https://www.facebook.com/OrbisHolographics/')} alt="facebook"/>
            </p>


        </div>
    );
};

