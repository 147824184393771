import React, {useEffect, useState} from 'react';
import "./Description.css";
import {useMediaQuery} from "react-responsive";
export const Description = ({text1, color1, text2, color2, text3, color3}) => {



    const [rotation, setRotation] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [textVisible, setTextVisible] = useState(false);
    useEffect(() => {
        const onScroll = () => {
            setRotation(window.scrollY / 13);
            setScrollPosition(window.scrollY); // Met à jour la position du scroll
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const scrollDetector = isDesktop ? 700 : 400;

    useEffect(() => {
        setTextVisible(scrollPosition >= scrollDetector);
    }, [scrollPosition, scrollDetector]);

    const getClassForColor = (color) => {
        switch (color) {
            case 'acid':
                return 'acid';
            case 'white':
                return textVisible ? 'dark-to-white' : 'white-to-dark';
            case 'grey':
                return textVisible ? 'white-to-dark' : 'dark-to-white';
            default:
                return ''; // Gérer autrement si nécessaire
        }
    }

    return (
        <div className="sectionDescription">
            <img src="images/section/description/roue.png" style={{ transform: `translate(-50%, -50%) rotate(${rotation}deg)` }} alt=""/>
            <div className="description">
                <span className={getClassForColor(color1)}> {text1}</span>
                <span className={getClassForColor(color2)}> {text2}</span>
                <span className={getClassForColor(color3)}> {text3}</span>
            </div>
        </div>
    );
};
