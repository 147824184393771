import React, {useEffect, useState} from 'react';
import "./PageAccueil.css";
import {Spacer} from "../../Spacer/Spacer";
import {BandeauAccueil} from "./BandeauAccueil/BandeauAccueil";
import {HomeDescription} from "./HomeDescription/HomeDescription";
import {Bandeau} from "./Bandeau/Bandeau";
import {HomeMediaHologramme} from "./HomeMediaHologramme/HomeMediaHologramme";
import {ButtonSite} from "../../Button/ButtonSite";
import {Helmet} from 'react-helmet';
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import {HomeProduct} from "./HomeProduct/HomeProduct";
import {Product} from "../../Section/Product/Product";
import {HomeMedia} from "./HomeMedia/HomeMedia";
import {HomeMediaAr} from "./HomeMediaAr/HomeMediaAr";
import {HomeHero} from "./HomeHero/HomeHero";
import {Clients} from "./Clients/Clients";
import {FirstLoad} from "../../Loader/FirstLoad/FirstLoad";
import {Temoignages} from "./Temoignages/Temoignages";
import {useMediaQuery} from "react-responsive";
import {HomeHeroVideo} from "./HomeHeroVideo/HomeHeroVideo";


export const PageAccueil = () => {

    const description = "Orbis Prod - Leader en technologie d'hologrammes à Paris. Découvrez des solutions holographiques innovantes et personnalisées pour votre entreprise. Visitez-nous pour en savoir plus.";

    const schema = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Orbis Holographics",
        "description": description,
        "url": "https://orbis-prod.fr",
        "logo": "https://orbis-prod.fr/orbis_logo.png",
        "telephone": "+33 1 40 92 11 53",
        "email": "contact@orbis-prod.fr",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "37 Rue Traversière",
            "addressLocality": "Boulogne-Billancourt",
            "postalCode": "92100",
            "addressCountry": "FR"
        },
        "sameAs": [
            "https://www.facebook.com/OrbisHolographics/",
            "https://www.instagram.com/orbisholographics/?hl=fr",
            "https://www.linkedin.com/company/orbisholographics/mycompany/",
            "https://www.youtube.com/channel/UCh3X9GbS32N4A42MNDO4TRQ",
        ],
        "video": [
            {
                "@type": "VideoObject",
                "name": "Orbis - Orbis Holographics",
                "description": "Bienvenue chez Orbis Holographics. Cette introduction vous guide à travers notre univers de technologies innovantes, depuis les hologrammes jusqu'à la réalité augmentée, et montre comment nous façonnons l'avenir de la communication visuelle.",
                "thumbnailUrl": "https://orbis-prod.fr/video/thumbnail/ORBIS.png",
                "uploadDate": "2023-11-09T15:00:00+01:00",
                "duration": "PT0M10S",
                "contentUrl": "https://orbis-prod.fr/video/hero/ORBIS.mp4"
            },
            {
                "@type": "VideoObject",
                "name": " Hologrammes - Orbis Holographics",
                "description": "Plongez dans l'univers fascinant des hologrammes avec Orbis. Cette vidéo présente nos innovations en matière d'holographie, démontrant comment nous apportons une dimension nouvelle à la communication et au divertissement visuel.",
                "thumbnailUrl": "https://orbis-prod.fr/video/thumbnail/HOLOGRAMMES.png",
                "uploadDate": "2023-11-09T15:00:00+01:00",
                "duration": "PT0M17S",
                "contentUrl": "https://orbis-prod.fr/video/hero/HOLOGRAMMES.mp4"
            },
            {
                "@type": "VideoObject",
                "name": "Studio 3D - Orbis Holographics",
                "description": "Entrez dans le monde de la création 3D chez Orbis. Notre vidéo vous emmène dans les coulisses de notre studio 3D, où l'art rencontre la technologie pour donner vie à des visualisations époustouflantes.",
                "thumbnailUrl": "https://orbis-prod.fr/video/thumbnail/STUDIO.png",
                "uploadDate": "2023-11-09T15:00:00+01:00",
                "duration": "PT0M22S",
                "contentUrl": "https://orbis-prod.fr/video/hero/STUDIO.mp4"
            },
            {
                "@type": "VideoObject",
                "name": "Réalité Augmentée - Orbis Holographics",
                "description": "Découvrez la magie de la technologie chez Orbis à travers cette vidéo captivante de 13 secondes. Nous y présentons une sélection de nos projets les plus impressionnants en hologrammes et réalité augmentée, illustrant notre capacité à créer des expériences visuelles uniques et immersives. Parfait pour apercevoir l'étendue de nos compétences innovantes.",
                "thumbnailUrl": "https://orbis-prod.fr/video/thumbnail/AR.png",
                "uploadDate": "2023-11-09T15:00:00+01:00",
                "duration": "PT0M13S",
                "contentUrl": "https://orbis-prod.fr/video/hero/AR.mp4"
            },
            {
                "@type": "VideoObject",
                "name": "Interactivité - Orbis Holographics",
                "description": "Vivez l'expérience unique de l'interaction avec des hologrammes chez Orbis. Cette vidéo dévoile nos solutions d'hologrammes interactifs, offrant des possibilités sans précédent pour l'éducation, le marketing et l'engagement du public.",
                "thumbnailUrl": "https://orbis-prod.fr/video/thumbnail/INTERACTIVITE.png",
                "uploadDate": "2023-11-09T15:00:00+01:00",
                "duration": "PT0M18S",
                "contentUrl": "https://orbis-prod.fr/video/hero/INTERACTIVITE.mp4"
            },
            {
                "@type": "VideoObject",
                "name": "Avatar IA - Orbis Holographics",
                "description": "Découvrez les avatars IA d'Orbis, une fusion révolutionnaire de l'intelligence artificielle et de l'holographie. Cette vidéo montre comment nos avatars interactifs peuvent transformer l'expérience client et créer des interactions personnalisées.",
                "thumbnailUrl": "https://orbis-prod.fr/video/thumbnail/IA.png",
                "uploadDate": "2023-11-09T15:00:00+01:00",
                "duration": "PT0M18S",
                "contentUrl": "https://orbis-prod.fr/video/hero/IA.mp4"
            },
            {
                "@type": "VideoObject",
                "name": "Réalisations - Orbis Holographics",
                "description": "Explorez notre portfolio de projets chez Orbis. Cette vidéo met en lumière nos réalisations les plus impressionnantes, illustrant notre expertise en technologie d'hologrammes et notre engagement envers l'innovation.",
                "thumbnailUrl": "https://orbis-prod.fr/video/thumbnail/REALISATION.png",
                "uploadDate": "2023-11-09T15:00:00+01:00",
                "duration": "PT0M10S",
                "contentUrl": "https://orbis-prod.fr/video/hero/REALISATION.mp4"
            },
        ]

    };

    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });


    return (
        <div>
            <Helmet>
                <title>Orbis - Innovation en Hologrammes</title>
                <meta name="description" content={description}/>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>



            <HomeHeroVideo/>
            <BandeauAccueil text1={"ORBIS "} text2={"HOLOGRAPHICS"}/>
            <HomeDescription/>


            {/*HOLOGRAMMES*/}
            <Bandeau text1={"NOS "} text2={"HOLOGRAMMES"} rotation="1.5deg"/>
           {/* <HomeMediaHologramme/>*/}
            <HomeMedia media={"hologramme"}/>
            <HomeProduct
                title1=""
                title2="HOLOGRAMMES"
                text="Pour augmenter l’impact de vos communications, nous sélectionnons les solutions les plus innovantes en les adaptant à votre message. Nos équipes sont constamment à l'affût des dernières technologies et innovations, les testant et les intégrant soigneusement à notre gamme de services."
                keyword="Plug & Play | Sur mesure"
                lien="/hologrammes"
                buttonText="Tous nos produits"
            />


            {/*INTERACTIVITE*/}
            <Bandeau text1={"INTER"} text2={"ACTIVITÉ"} rotation="-1.5deg"/>
            <HomeMedia media={"interactivite"}/>
            <HomeProduct
                title1="INTER"
                title2="ACTIVITÉ"
                text="Transformez vos idées en expériences interactives captivantes grâce à des systèmes de contrôle intuitifs. Offrez à votre audience la chance d'explorer et d'interagir avec l'univers virtuel sur mesure de votre marque. Plongez-les dans des scénarios immersifs conçus spécialement pour eux, et laissez-les découvrir votre marque d'une manière inédite et engageante."
                keyword="Eye tracking | Motion tracking | Voice Recognizer"
                button={3}
                lien="/interactivite"
                buttonText="En savoir plus"
            />

            {/*STUDIO*/}
            <Bandeau text1={"NOTRE "} text2={"STUDIO"} rotation="1.5deg"/>
            <HomeMedia media={"studio"}/>
            <HomeProduct
                title1="NOTRE "
                title2="STUDIO"
                text="Notre studio en interne nous permet de développer nos propres éxperiences holographiques sur mesure. Nous offrons également une vaste gamme de services lié à la production audiovisuelle classique (clip et montage vidéo, shooting photo, social content, mapping, VR, web design, branding) et bien d’autres."
                keyword="En interne"
                button={1}
                lien="/studio"
                buttonText="Visiter le studio"
            />


            {/*RÉALITÉ AUGMENNTÉE*/}
            <Bandeau text1={"RÉALITÉ "} text2={"AUGMENTÉE"} rotation="-1.5deg"/>
            {/*<HomeMediaAr />*/}
            <HomeMedia media={"ar"}/>

            <HomeProduct
                title1="RÉALITÉ"
                title2=" AUGMENTÉE"
                text="Émergeant comme l'outil indispensable du contenu social, la réalité augmentée est en passe de devenir un acteur incontournable du monde de demain en ajoutant à notre monde réel des éléments virtuels."
                keyword="Instagram | Snapchat | Tiktok | WebAR | App Native"
                button={3}
                lien="/ar"
                buttonText="En savoir plus"
            />

            {/*INTELLIGENCE ARTIFICIELLE*/}
            <Bandeau text1={"INTELLIGENCE "} text2={"ARTIFICIELLE"} rotation="1.5deg"/>
            <HomeMedia media={"ia"} />
            <HomeProduct
                title1="i"
                title2="AVATAR"
                text={"Faites de votre avatar une entité conversationnelle dynamique et immersive, prête à fournir des interactions personnalisées et enrichissantes à chaque utilisateur. Notre technologie s’appuie sur OpenAI et Mistral AI, deux des outils d'intelligence artificielle les plus puissants du marché."}
                keyword="OpenAI | Mistral | Sora | ChatGPT | Avatar 3D"
                button={1}
                lien="/ia"
                buttonText="En savoir plus"
            />


            <Clients />
            <Temoignages />
        </div>
    );
};


