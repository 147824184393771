import React, {useEffect} from 'react';
import "./Remerciement.css";

export const Remerciement = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="Remerciement">
            <h1 className="question">une question ?</h1>
            <h1 className="devis">un devis ?</h1>
            <h2 className="ecoute">On vous écoute !</h2>
            <p className="paragraphe1">Que ce soit pour un renseignement sur un produit ou un service, deviser un projet ou pour faire connaissance, n’hésitez-pas à nous contacter via le formulaire ci-dessous.</p>
            <p className="paragraphe2">Nous vous recontacterons dans les plus bref délais pour répondre à vos besoins.</p>

            <div className="message">C’est envoyé ! Nous vous recontacterons au plus vite !</div>



        </div>
    );
};

