import React from 'react';
import "./PageRealisation.css";
import {Presentation} from "../../Section/Hero/Hero";
import {Helmet} from "react-helmet";
import Gallery from "./Gallery/Gallery";
import {HeroVideo} from "../../Section/HeroVideo/HeroVideo";
import {useMediaQuery} from "react-responsive";


export const PageRealisation = () => {

    const description = "Explorez le portfolio d'Orbis : une vitrine de nos réalisations en hologrammes et réalité augmentée. Découvrez une gamme de projets innovants, allant de l'affichage dynamique aux expériences immersives, qui témoignent de notre expertise et de notre créativité dans le domaine de la technologie visuelle avancée.";
    const schema = {
        "@context": "http://schema.org",
        "@type": "Service",
        "name": "Nos réalisations",
        "description": description,
        "url": "https://orbis-prod.fr/realisations",
        "provider": {
            "@type": "Organization",
            "name": "Orbis Holographics"
        }
    };
    const isMobileMedia = useMediaQuery({ query: '(min-width: 700px)' });

    const video = isMobileMedia ? "video/hero/CASESTUDIES.mp4": "video/hero/HOLOGRAMMES_MOBILE.mp4";


    return (
        <div>
            <Helmet>
                <title>Nos Réalisations - Orbis Hologrammes et Réalité Augmentée</title>
                <meta name="description" content={description} />
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>
            <HeroVideo videoSrc={video} titre1="nos" titre2="réalisations" description="instaorbis"/>

            {/*<Presentation img="images/section/presentation/realisations.png" titre1="nos" titre2="réalisations" description="Retrouvez tous nos projets sur Instagram : @orbisholographics"/>*/}

            <Gallery/>

        </div>
    );
};

