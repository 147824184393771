import React from 'react';
import {Presentation} from "../../Section/Hero/Hero";
import {Description} from "../../Section/Description/Description";
import {Helmet} from "react-helmet";
import {GoogleMap} from "./GoogleMap/GoogleMap";
import {ContactHero} from "./ContactHero/ContactHero";
import {ContactDatas} from "./ContactDatas/ContactDatas";
import {ContactForm} from "./ContactForm/ContactForm";



export const PageContact = () => {

    const description = "Explorez la galerie d'hologrammes d'Orbis, offrant une gamme variée de créations holographiques uniques. De l'affichage publicitaire aux installations artistiques, découvrez comment nos hologrammes révolutionnent la visualisation et la communication. Parfait pour les événements, le marketing et l'art visuel.";

    const schema = {
        "@context": "http://schema.org",
        "@type": "ContactPage",
        "name": "Contact",
        "description": description,
        "url": "https://orbis-prod.fr/contact",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "37 Rue Traversière",
            "addressLocality": "Boulogne-Billancourt",
            "postalCode": "92100",
            "addressCountry": "FR"
        },
        "telephone": "+33 1 40 92 11 53",
        "email": "contact@orbis-prod.fr",
        "openingHours": "Mo-Fr 09:00-18:00"
    };


    return (
        <div>

            <Helmet>
                <title>Contactez Orbis - Expert en Solutions Holographiques</title>
                <meta name="description" content={description}/>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>

            </Helmet>
            <ContactHero img="./images/contact/map_cover.png" titre1="nous" titre2="contacter" description="On vous écoute"/>
            <ContactForm/>



            <ContactDatas/>


        </div>
    );
};

