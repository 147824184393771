import React from 'react';
import "./HomeProduct.css";
import {ButtonSite} from "../../../Button/ButtonSite";

export const HomeProduct = ({title1, title2, text, keyword, button, buttonText, lien}) => {

    switch (button) {
        case 1:
            button = "images/button/star.png";
            break;
        case 2:
            button = "images/button/lune.png";
            break;
        case 3:
            button = "images/button/o.png";
            break;
        default:
            button = "images/button/o.png";
            break;
    }

    return (
        <div className="homeProduct">

            <a href={lien}>
            <h1 className="title">
                <span className="bai">{title1}</span>
                <span className="druk">{title2}</span>
            </h1>
            </a>
            <div className="text"> {text}</div>
            <div className="keyword">{keyword}</div>
            <div className="productButton">
                <ButtonSite lien={lien} logo={button} text={buttonText}/> <br/>
            </div>


        </div>
    );
};
