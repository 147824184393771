import React, { useState } from "react";
import "./Carousel.css";
import {ButtonSite} from "../../../Button/ButtonSite";

export const Carousel = ({media1, media2, media3, media4, media5, media6}) => {
    const [currentContent, setCurrentContent] = useState({ id: media1.id, video: media1.video, text: media1.text , description : media1.description});
    const [animationClass, setAnimationClass] = useState('fade-in'); // nouvel état pour la classe d'animation

    const handleClick = (content) => {
        setAnimationClass('fade-out'); // commencez par disparaître
        setTimeout(() => {
            setCurrentContent(content);
            setAnimationClass('fade-in'); // puis apparaissez avec le nouveau contenu
        }, 500); // le délai doit correspondre à la durée de l'animation
    };


    return (
        <div className="carouselStudio">
            <div className="buttons">
                <div style={{opacity: currentContent.id === 1 ? 1 : 0.5}}  className={currentContent.id === 1 ? "selected" : ""} onClick={() => handleClick(media1)}><div className="button-text"><span className="bai">{media1.title1}</span> <span className="druk"> {media1.title2}</span></div></div>
                <div style={{opacity: currentContent.id === 2 ? 1 : 0.5}}  className={currentContent.id === 2 ? "selected" : ""} onClick={() => handleClick(media2)}><div className="button-text"><span className="bai">{media2.title1}</span> <span className="druk">{media2.title2}</span></div></div>
                <div style={{opacity: currentContent.id === 3 ? 1 : 0.5}}  className={currentContent.id === 3 ? "selected" : ""} onClick={() => handleClick(media3)}><div className="button-text"><span className="bai">{media3.title1}</span> <span className="druk">{media3.title2}</span></div></div>
                <div style={{opacity: currentContent.id === 4 ? 1 : 0.5}}  className={currentContent.id === 4 ? "selected" : ""} onClick={() => handleClick(media4)}><div className="button-text"><span className="bai">{media4.title1}</span> <span className="druk">{media4.title2}</span></div></div>
                <div style={{opacity: currentContent.id === 5 ? 1 : 0.5}}  className={currentContent.id === 5 ? "selected" : ""} onClick={() => handleClick(media5)}><div className="button-text"><span className="bai">{media5.title1}</span> <span className="druk">{media5.title2}</span></div></div>
                <div style={{opacity: currentContent.id === 6 ? 1 : 0.5}}  className={currentContent.id === 6 ? "selected" : ""} onClick={() => handleClick(media6)}><div className="button-text"><span className="bai">{media6.title1}</span> <span className="druk">{media6.title2}</span></div></div>
            </div>

            <div className={`carousel ${animationClass}`}>

                <div className="left">

                    <video
                        key={currentContent.video} // Ajout d'une clé unique pour forcer le rechargement
                        className="img"
                        muted={true}
                        autoPlay="autoplay"
                        loop={true}
                        playsInline
                        style={{ pointerEvents: 'none' }}
                        controls={false}
                        preload="auto"
                    >
                        <source src={currentContent.video} type="video/mp4" />
                    </video>


                </div>

                <div className="right">
                    <div className="description"> <img className="batton" src="images/studio/batton.png" alt="studio-orbis" />{currentContent.description}</div>
                    <div className="text">{currentContent.text}</div>
                </div>
            </div>
            <div className="noanim">
                <img className="carreacid" src="images/studio/carreacid.png" alt="studio-orbis" />
                <div className="button"> <ButtonSite lien="/contact" logo="images/button/lune.png" text="Nous contacter"/> <br /> </div>
            </div>

        </div>
    );
};
