import React, { useRef, useEffect, useState } from 'react';
import './BandeauAccueil.css';

export const BandeauAccueil = ({ text1, text2 }) => {
    const textRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        // S'assure que `window` est disponible avant de l'utiliser
        if (typeof window !== 'undefined') {
            const scrollY = window.scrollY;
            setScrollPosition(scrollY);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []); // l'array vide signifie que cet effet s'exécutera une fois lors du montage et lors du démontage du composant

    const translateX = -scrollPosition / 1.5 ; // Adjust the condition here
    let textSpans = [];
    for(let i = 0; i < 3; i++) {
        textSpans.push(
            <React.Fragment key={i}>
                <span className="fontBai">{text1}</span>
                <span className="fontDruk">{text2} - </span>
            </React.Fragment>
        );
    }


    return (
        <div>
            <div className="BandeauAccueil up">
                <h1 className="text" style={{ transform: `translateX(${-translateX*0.5}px)` }} ref={textRef}>
                    {textSpans}
                </h1>
            </div>
            <div className="BandeauAccueil down">
                <h1 className="text" style={{ transform: `translateX(${translateX}px)` }} ref={textRef}>
                    {textSpans}
                </h1>
            </div>
        </div>
    );
};
