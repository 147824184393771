import React, {useEffect, useState} from 'react';
import "./Footer.css";
import {ButtonSocial} from "../Button/ButtonSocial";
import {useMediaQuery} from "react-responsive";


export const Footer = () => {

    const cgv = () => {
        window.location.href = '/cgv';
    }

    const carre = "images/button/carre.png";
    const carreacid = "images/button/carreacid.png";
    const [logoCarre, setLogoCarre] = useState(carre);
    const handleMouseOver = () => {
        setLogoCarre(carreacid);
    };
    const handleMouseOut = () => {
        setLogoCarre(carre);
    };

    const [rotation, setRotation] = useState(0);
    const isDesktop = useMediaQuery({ query: '(min-width: 1439px)' });


    useEffect(() => {
        const onScroll = () => {
            setRotation(window.scrollY / 10);
        };

        if (isDesktop) {
            window.addEventListener("scroll", onScroll);
        }

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [isDesktop]);


    const contact = () => {
        window.location.href = '/contact';
    }

    const FooterDesktop = () => {
        return (
            <footer className="footer">
                <div className="container">

                    <div className="left">
                        <div>
                            <img src="images/footer/logo_orbis.png" alt="logo_orbis"/>
                        </div>
                        <div>
                            <ButtonSocial lien="https://www.facebook.com/OrbisHolographics/" platform="Facebook" marginTop="40px"/> <br/>
                            <ButtonSocial lien="https://www.instagram.com/orbisholographics/?hl=fr"
                                          platform="Instagram" marginTop="40px"/> <br/>
                            <ButtonSocial lien="https://www.linkedin.com/company/orbisholographics/mycompany/"
                                          platform="Linkedin" marginTop="40px"/> <br/>
                            <ButtonSocial lien="https://www.youtube.com/channel/UCh3X9GbS32N4A42MNDO4TRQ"
                                          platform="Youtube" marginTop="40px"/> <br/>
                        </div>

                        <div onClick={contact} className="hello" data-text="say hello">
                            say hello
                        </div>
                        <img className="elipse" src="images/footer/elipse.png" alt="logo_elipse"/>

                        <a className="phone" href="tel:0140921153">
                            01 40 92 11 53
                        </a>
                        <a className="mail" href="mailto:contact@orbis-prod.fr">
                            contact@orbis-prod.fr
                        </a>
                    </div>

                    <div className="right">
                        <div>
                            <img className="img" src="images/footer/roue.png"
                                 style={{transform: `rotate(${rotation}deg)`}} alt="logo_roue"/>
                        </div>
                        <a className="cgv" href="/pdf/CGV_ORBIS.pdf" target="_blank" rel="noopener noreferrer"
                           onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            CGV &nbsp;&nbsp;
                            <img className="logo_carre" src={logoCarre} alt="logo_carre"/>


                        </a>

                        <div className="copyright">
                            Copyright | ORBIS - 2023
                        </div>

                    </div>
                </div>
            </footer>
        )
    }

    const FooterMobile = () => {
        return (
            <footer className="footerM">


                <img className="trait" src="images/footer/footer_trait.png" alt="footer_trait"/>
                <img className="logo" src="images/footer/logo_orbis.png" alt="logo_orbis"/>


                <div className="hello"  onClick={contact} data-text="say hello">
                    say hello
                </div>
                <img className="elipse" src="images/footer/elipse.png" alt="logo_elipse"/>


                <a className="phone" href="tel:0140921153">
                    01 40 92 11 53
                </a>
                <a className="mail" href="mailto:contact@orbis-prod.fr">
                    contact@orbis-prod.fr
                </a>


                <div className="social">
                    <ButtonSocial lien="https://www.facebook.com/OrbisHolographics/" platform="Facebook"/><br/>
                    <ButtonSocial lien="https://www.instagram.com/orbisholographics/?hl=fr" platform="Instagram"/><br/>
                    <ButtonSocial lien="https://www.linkedin.com/company/orbisholographics/mycompany/"
                                  platform="Linkedin"/><br/>
                    <ButtonSocial lien="https://www.youtube.com/channel/UCh3X9GbS32N4A42MNDO4TRQ" platform="Youtube"/><br/>

                </div>


                <a className="cgv" href="/pdf/CGV_ORBIS.pdf" target="_blank" rel="noopener noreferrer"
                   onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    CGV &nbsp;&nbsp;
                    <img className="logo_carre" src={logoCarre} alt="logo_carre"/>


                </a>
                <div className="copyright">
                    Copyright | ORBIS - 2023
                </div>


            </footer>
        )
    }


    return (
        <>
            {isDesktop ? <FooterDesktop/> : <FooterMobile/>}
        </>
    );
};

