import React, { useState } from "react";
import './MenuBurger.css';


export const MenuBurger = ({ isOpen, onClose }) => {

    const accueil = () => {window.location.href = '/accueil';}
    const hologramme = () => {window.location.href = '/hologrammes';}
    const studio = () => {window.location.href = '/studio';}
    const ar = () => {window.location.href = '/ar';}
    const interactivite = () => {window.location.href = '/interactivite';}
    const ia = () => {window.location.href = '/ia';}
    const realisation = () => {window.location.href = '/realisations';}
    const contact = () => {window.location.href = '/contact';}
    const shop = () => {window.location.href = 'https://shop.orbis-prod.fr/';}

    const [isHovered, setIsHovered] = useState(false);


    return (
        <div className={`menuBurger ${isOpen ? 'open' : ''}`}>
            <div className="burgerContainer">


                <div className="sections">

                    <div onClick={hologramme} className="section">
                        <p className="text">hologrammes</p>
                    </div>
                    <div onClick={studio} className="section">
                        <p className="text">studio</p>
                    </div>
                    <div onClick={ar} className="section">
                        <p className="text">Réalitée augmentée</p>
                    </div>
                    <div onClick={interactivite} className="section">
                        <p className="text">interactivité</p>
                    </div>
                    <div onClick={ia} className="section">
                        <p className="text">ia</p>
                    </div>
                    <div onClick={realisation} className="section">
                        <p className="text">nos réalisations</p>
                    </div>

                    <div onClick={contact} className="section">
                        <p className="text">contact</p>
                    </div>





{/*
                    <div className="hello2 ">
                        Say hello -
                        <a className="tel2" href="tel:0140921153"> 01 40 92 11 53</a>
                        <img
                            src="./images/accueil/stars.png" alt=""/>
                    </div>
                    */}

                </div>



            </div>


        </div>
    );
};

