import React, {useState} from 'react';
import "./ContactForm.css";
import {useNavigate} from 'react-router-dom';

export const ContactForm = () => {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [mail, setMail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [objet, setObjet] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [emailSent, setEmailSent] = useState(false);
    const navigate = useNavigate(); // Initialisez useNavigate ici

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!mail || !telephone || !message) {
            setErrorMessage("Veuillez remplir tous les champs obligatoire");
            return;
        }

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(mail)) {
            setErrorMessage("Veuillez entrer une adresse e-mail valide.");
            return;
        }
        const phoneRegex = /^[0-9-+()\s]+$/;
        if (!phoneRegex.test(telephone)) {
            setErrorMessage("Veuillez entrer un numéro de téléphone valide.");
            return;
        }


        setEmailSent(true);

        const formData = {
            nom,
            prenom,
            mail,
            telephone,
            objet,
            message
        };

        try {
            const response = await fetch('https://orbis-prod.fr/api/mail/confirmation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();

            if (response.ok) {
                setEmailSent(true);
                setErrorMessage(null);

                setTimeout(function() {
                    window.location.href = "https://orbis-prod.fr/confirmation";
                }, 2000);


            } else {
                throw new Error(result.message || 'Une erreur s\'est produite lors de l\'envoi de l\'email.');
            }
        } catch (error) {
            setEmailSent(false);
            setErrorMessage(error.toString());
        }
    };


    return (
        <div className="ContactForm">
            <h1 className="question">une question ?</h1>
            <h1 className="devis">un devis ?</h1>
            <h2 className="ecoute">On vous écoute !</h2>
            <p className="paragraphe1">Que ce soit pour un renseignement sur un produit ou un service, deviser un projet
                ou pour faire connaissance, n’hésitez-pas à nous contacter via le formulaire ci-dessous.</p>
            <p className="paragraphe2">Nous vous recontacterons dans les plus bref délais pour répondre à vos
                besoins.</p>

            <form onSubmit={handleSubmit} className="formulaire">
                <label className="labelNom" htmlFor="nom">Nom</label>
                <input id="nom" className="nom" type="text" value={nom} onChange={(e) => setNom(e.target.value)}/>

                <label className="labelPrenom" htmlFor="prenom">Prénom</label>
                <input id="prenom" className="prenom" type="text" value={prenom}
                       onChange={(e) => setPrenom(e.target.value)}/>

                <label className="labelMail" htmlFor="mail">Mail <span style={{color: '#CCF300'}}>*</span></label>
                <input id="mail" className="mail" type="email" value={mail} onChange={(e) => setMail(e.target.value)}/>

                <label className="labelTel" htmlFor="tel">Téléphone <span style={{color: '#CCF300'}}>*</span></label>
                <input id="tel" className="tel" type="tel" value={telephone}
                       onChange={(e) => setTelephone(e.target.value)}/>

                <label className="labelObjet" htmlFor="objet">Objet de votre demande</label>
                <input id="objet" className="objet" type="text" value={objet}
                       onChange={(e) => setObjet(e.target.value)}/>

                <label className="labelMessage" htmlFor="message">Dites-nous tout : <span
                    style={{color: '#CCF300'}}>*</span> </label>
                <textarea id="message" className="message" value={message}
                          onChange={(e) => setMessage(e.target.value)}></textarea>

                <div className="asterix">* champ obligatoire</div>

                {errorMessage && <div className="errorPopup">{errorMessage}</div>}
                <button type="submit">Envoyer</button>


            </form>


        </div>
    );
};

