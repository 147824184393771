import React from 'react';
import "./MainLoader.css";

export const MainLoader = () => {

    return (
        <div className="loader">
            <img src="images/loader.png" alt="Loading..." className="rotating-logo" />
        </div>
    );
};

