import React from 'react';

export const Spacer = ({ height }) => {
    const spacerStyle = {
        height: `${height}px`,
        width: '100%',
    };

    return <div style={spacerStyle}></div>;
};


