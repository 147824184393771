import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./style.css";

const rootElement = document.getElementById('root');
if (rootElement !== null) {
    ReactDOM.createRoot(rootElement).render(
        <React.StrictMode>
            <App />

        </React.StrictMode>
    );
}
