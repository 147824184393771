import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Slider.css';

const Slider = ({ images = [] }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [targetScroll, setTargetScroll] = useState(null);
    const [contentWidth, setContentWidth] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);

    const sliderRef = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            setContentWidth(sliderRef.current.scrollWidth);
            setWindowWidth(sliderRef.current.clientWidth);
        }
    }, [images]);
    const animateScroll = () => {
        if (targetScroll === null) return;

        const currentScroll = sliderRef.current.scrollLeft;
        const moveAmount = (targetScroll - currentScroll) * 0.05;

        if (Math.abs(moveAmount) < 0.5) {
            sliderRef.current.scrollLeft = targetScroll;
            setTargetScroll(null);
        } else {
            sliderRef.current.scrollLeft = currentScroll + moveAmount;
            requestAnimationFrame(animateScroll);
        }
    };
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };
    const handleMouseLeave = () => {
        setIsDragging(false);
    };
    const handleMouseUp = () => {
        setIsDragging(false);
    };
    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        setTargetScroll(scrollLeft - walk);
        animateScroll();
    };
    const scrollbarWidth = (windowWidth / contentWidth) * windowWidth;
    const scrollbarPosition = (sliderRef.current?.scrollLeft || 0) / contentWidth * windowWidth;


    return (
        <div className="Slider">

            <img className="carre" src="./images/accueil/carreacidleft.png" alt="carre"/>
            {/*<img className="background" src="./images/interactivite/bg_interaction.png" alt="background"/>*/}


            <h1 className="sectionName"> <span className="bai">nos </span><span className="druk">expériences ar</span>  </h1>
            <h2 className="description"> Expérience Ar Made in Orbis  </h2>




            <div className="slider-container"
                 ref={sliderRef}
                 onMouseDown={handleMouseDown}
                 onMouseLeave={handleMouseLeave}
                 onMouseUp={handleMouseUp}
                 onMouseMove={handleMouseMove}
                 style={{ display: 'flex', cursor: isDragging ? 'grabbing' : 'grab' }}
            >
                {images.map((datas, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', margin: '20px' }}>
                        <motion.img
                            src={datas.src}
                            style={{ width: '233px', height: '495px', userDrag: 'none', pointerEvents: isDragging ? 'none' : 'all' }}
                        />
                        <h3>{datas.title}</h3>
                        <p>{datas.date}</p>
                    </div>
                ))}
            </div>
            <div className="scrollbar-container" style={{marginTop:'200px', marginLeft:'50px', marginRight:'50px', height: '3px', width: '100%', backgroundColor: '#505050' }}>
                <div style={{
                    width: `${scrollbarWidth}px`,
                    height: '3px',
                    backgroundColor: '#CCF300',
                    transform: `translateX(${scrollbarPosition}px)`
                }}></div>
            </div>
        </div>
    );
};

export default Slider;
