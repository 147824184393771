export const getHologrammeProduct  = () => {
    return [
        {
            id:1,
            title : "Bornes",
            text: "En format standard ou sur-mesure, la taille des bornes holographiques varie de quelques centimètres à plusieurs mètres, en fonction des besoins. Il est possible d’y introduire un / des produit(s) et de mettre en scène ces derniers dans un décors habillé par l’hologramme pour créer un effet ‘phygital’, trompant l’œil du spectateur entre le réel et le virtuel. Plus aucune limite à votre créativité, cette gamme s’adapte à vous!",
            img: "./images/hologramme/bornes.png",
            keyword: "POP | HD | XL | Diamond | Holosmart",
            video:"video/hologrammes/BORNES.mp4"
        },
        {
            id:2,
            title : "Hélices led",
            text: "Élevez votre marque à de nouveaux sommets avec nos hélices LED, le support incontournable pour une visibilité optimale, de jour comme de nuit. Avec une gamme de tailles variées et la possibilité de les combiner pour créer des murs holographiques saisissants, nos hélices LED sont l'atout parfait pour dynamiser votre communication en boutique ou lors d'événements. Capturez l'attention de tous, où que vous soyez, avec cet outil spectaculaire.",
            img: "images/hologramme/helices.png",
            keyword: "H56 | H75 | Smart V | Mur d’hélices",
            video:"video/hologrammes/HELICES.mp4"
        },
        {
            id:3,
            title : "Projections",
            text: "Explorez de nouveaux horizons avec nos projections holographiques, offrant une immersion incomparable dans un univers captivant. Que ce soit pour donner vie à un personnage, un objet ou un décor, la projection holographique est le moyen ultime de transporter les spectateurs dans l'univers de votre marque. Plongez-les dans une expérience visuelle époustouflante qu'ils n'oublieront pas de sitôt.",
            img: "images/hologramme/projections.png",
            keyword: "Holoscène | Holotoile | Vitrine | Mapping",
            video:"video/hologrammes/PROJECTIONS.mp4"
        },
        {
            id:4,
            title : "Mur de brume",
            text: "Franchissez le voile de la brume sèche, où votre logo, des films promotionnels ou des hologrammes personnalisés prennent vie. La brume sèche est l'outil idéal pour inviter les visiteurs à explorer l'univers de votre marque. Cette solution innovante aux possibilités infinies promet une expérience magique, immersive et mémorable pour vos visiteurs, clients et prospects. Plongez-les dans un monde où la réalité et la fantaisie se rencontrent pour créer des souvenirs inoubliables.",
            img: "images/hologramme/mur.png",
            keyword: "Ludique | Sur mesure",
            video:"video/hologrammes/BRUME.mp4"
        },
        {
            id:5,
            title : "Looking Glass",
            text: "Dites adieu à la banalité des écrans plats traditionnels. Avec le Looking Glass, entrez dans une nouvelle ère de l'expérience visuelle. Transformez votre espace d'exposition en un monde dynamique et immersif grâce à son effet holographique. En magasin, remplacez les vitrines par des configurateurs de produits en 3D, offrant à vos clients une expérience d'achat interactive et captivante. Lors d'événements, présentez vos dernières nouveautés en trois dimensions à vos clients potentiels, les plongeant au cœur de votre univers de marque. Les possibilités sont infinies avec le Looking Glass.",
            img: "images/hologramme/lookingGlass.png",
            keyword: "Revendeur officiel Looking Glass",
            video:"video/hologrammes/LOOKING GLASS.mp4"
        },
        {
            id:6,
            title : "Tablette Leia",
            text: "Plongez dans une nouvelle ère de l'expérience utilisateur avec la LeiaTab, la tablette 3D ultime. En associant un écran holographique à un suivi du visage par intelligence artificielle, elle offre une expérience de visionnage en 3D incomparable. Complétée par une configuration de quatre haut-parleurs Dolby Atmos, elle assure une immersion totale dans votre contenu. Offrez à vos utilisateurs la possibilité d'interagir avec l'univers de votre marque de manière révolutionnaire, repoussant les frontières de la technologie et de l'innovation en communication digitale.",
            img: "images/hologramme/leia.png",
            keyword: "Revendeur officiel Leia Inc.",
            video:"video/hologrammes/NUBIA 3D.mp4"
        },
        {
            id:7,
            title : "Ghostscreen",
            text: "Préparez-vous à une révolution : l'Holosafe offre la possibilité de projeter une image tactile dans l'air. Interagissez avec l'hologramme en touchant son écran flottant dans l'air et offrez ainsi à votre audience une toute nouvelle manière d'explorer l'univers de votre marque et vos produits. Vous en rêviez en regardant Star Wars - c'est maintenant une réalité !",
            img: "images/hologramme/ecrans.png",
            keyword: "Taille variable | Combinable à l'infini",
            video:"video/hologrammes/GHOSTSCREEN.mp4"
        },
        {
            id:8,
            title : "Holosafe",
            text: "Grâce à la technologie de l’holosafe, vous pouvez projeter, dans les airs, une image tactile. Le secret est une dalle hologramique positionner à 45 degré d’un écran de projection, créant ainsi un hologramme flottant dans votre environnement. Vous avez dit Star Wars? Nous y sommes !",
            img: "images/hologramme/holosafe.png",
            keyword: "Tactile | Interactif ",
            video:"video/hologrammes/HOLOSAFE.mp4"

        },
        {
            id:9,
            title : "Human Box",
            text: "Découvrez notre dernier ajout révolutionnaire : la HumanBox. Offrant une présence humaine réaliste à taille réelle, elle vous permet d'interagir avec votre audience comme si vous étiez ensemble dans la même pièce. Que ce soit en photocall, en élément interactif d'une scénographie ou en tant que speaker principal lors d'un événement, la HumanBox invite votre audience à plonger au cœur de l'univers de votre marque, offrant ainsi une expérience immersive et inoubliable.",
            img: "images/hologramme/humanbox.png",
            keyword: "Interactif | Social | Temps réel | etc.",
            video:"video/hologrammes/HUMANBOX.mp4"

        }
    ];
};