import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname + location.search;
        window.gtag('config', 'AW-640569270', {
            'page_path': currentPath,
        });
        console.log("curent path" + currentPath);

    }, [location]);

    return null;
};



export default RouteChangeTracker;


