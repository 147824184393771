import React from 'react';
import "./ContactHero.css";
import {GoogleMap} from "../GoogleMap/GoogleMap";


export const ContactHero = ({img, titre1, titre2, description}) => {
    const sectionStyle = {
        backgroundImage: `url(${img})`
    };

    return (
        <div className="ContactHero" >
            <div className="container">
                <h1 className="titre">
                    <div className="fontBai">{titre1}</div>
                    <div className="fontDruk">{titre2}</div>
                </h1>
                <p className="description">
                    {description}
                </p>

            </div>
            <GoogleMap />

            {/*<div className="cover" style={sectionStyle}> </div>*/}
        </div>
    );
};

