import React from 'react';
import "./Temoignages.css";
import {useMediaQuery} from "react-responsive";

export const Temoignages = () => {
    const Avis = ({ className, imageSrc, name, title, paragraphe }) => {
        return (
            <div className={`avis ${className}`}>
                <div className="texte">
                    <h2>{name}</h2>
                    <h3>{title}</h3>
                    <p>{paragraphe}</p>
                </div>
            </div>
        );
    };


    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    return (
        <div className="Temoignages">
            <h1 className="titre">temoignages </h1>
                <div className="avis-container">


                <Avis   className="avis1"
                        name="LVMH - Director, House & Executive Development"
                        title="- Septembre 2023"
                        paragraphe= "Bravo à vous Yvan et à toute l’équipe ORBIS pour ce beau résultat avec une Maison et une organisation très exigeante !">
                </Avis>

                <Avis   className="avis2"
                        name="Arianee - Event manager"
                        title="- Février 2024"
                        paragraphe= "We wanted to give a big shout-out to the team for its invaluable support and for successfully developing the Arianee app on the ghost screen in such a short period with outstanding results. The ghost screen truly elevated our event and people loved the experience. We managed to launch this experience on time, so congratulations to all! We would be more than happy to collaborate again.">
                </Avis>

                <Avis   className="avis3"
                        name="LVMH - Head of Global Digital Store Brand Experience"
                        title="- Avril 2024"
                        paragraphe= "Merci pour votre professionnalisme, créativité et flexibilité pour réaliser avec nous cette expérience qui a beaucoup plu. Nous ne manquerons pas de vous recontacter si nous devions donner suite ou développer de nouveaux projets. Encore bravo aux équipes ORBIS.">
                </Avis>

            </div>
         </div>
    );
};

