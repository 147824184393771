import React from 'react';
import "./HomeMedia.css";
import {useMediaQuery} from "react-responsive";

export const HomeMedia = ({media, bg, width, height}) => {

    switch (media) {
        case "hologramme":
            media = "video/hero/HOLOGRAMMES.mp4";
            break;
        case "interactivite":
            media = "video/hero/INTERACTIVITE.mp4";
            break;
        case "ia":
            media = "video/hero/IA.mp4";
            break;
        case "ar":
            media = "video/hero/AR.mp4";
            break;
        case "studio":
            media = "video/hero/STUDIO.mp4";
            break;





    }



    return (
        <div className="HomeMedia">
            <div className="container">
                <video className="videoHomeMedia"
                       alt="HeroVideo"
                       autoPlay
                       muted
                       loop
                       playsInline
                       controls={false}
                       style={{pointerEvents: 'none'}}>
                    <source src={media} type="video/mp4"/>
                    Votre navigateur ne prend pas en charge les vidéos HTML5.
                </video>


            </div>
        </div>

    );
}