import React from "react";
import './ArrowReturn.css';

const  ArrowReturn = ({ isReversed }) => {
    const arrowClasses = `arrow-picto ${isReversed ? "arrow-picto-reversed" : ""}`;
    return (
        <div className="arrow-container">
            <svg className={arrowClasses} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7L15 7M1 7L7 1M1 7L7 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    )
}
export default ArrowReturn;