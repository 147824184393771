import React from 'react';
import "./PageIA.css";
import {Presentation} from "../../Section/Hero/Hero";
import {Description} from "../../Section/Description/Description";
import {Helmet} from "react-helmet";
import {Product} from "../../Section/Product/Product";
import {Spacer} from "../../Spacer/Spacer";
import {ProductReverse} from "../../Section/Product/ProductReverse";
import {TripleImage} from "../../Section/TripleImage/TripleImage";
import {useMediaQuery} from "react-responsive";
import {HeroVideo} from "../../Section/HeroVideo/HeroVideo";
import {AiLogos} from "./AiLogos/AiLogos";



export const PageIA = () => {

    const description = "Découvrez la révolution de l'avatar IA chez Orbis : Interaction en temps réel avec des hologrammes. Notre technologie d'intelligence artificielle avancée permet des conversations fluides et réalistes avec des avatars hologrammes, ouvrant de nouvelles dimensions pour l'engagement client, la formation et le divertissement.";

    const schema = {
        "@context": "http://schema.org",
        "@type": "Service",
        "name": "Avatar IA",
        "description": description,
        "url": "https://orbis-prod.fr/ia",
        "provider": {
            "@type": "Organization",
            "name": "Orbis Holographics"
        }
    };

    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const isMobileMedia = useMediaQuery({ query: '(min-width: 700px)' });
    const video = isMobileMedia ? "video/hero/IA.mp4": "video/hero/IA_MOBILE.mp4";
    return (
        <div>

            <Helmet>
                <title>IA Interactive chez Orbis - Dialoguez avec des Hologrammes</title>
                <meta name="description" content={description}/>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>

            {/*<Presentation img="images/section/presentation/ia.png" titre1="intelligence" titre2="artificielle" description="Votre nouvel assistant virtuel"/>*/}
            <HeroVideo videoSrc={video} titre1="intelligence" titre2="artificielle" description="Votre nouvel assistant virtuel"/>


            <Description color1="acid"  text1="Faites de votre avatar une entité conversationnelle dynamique et immersive, prête à fournir des interactions personnalisées et enrichissantes à chaque utilisateur. "
                         color2="grey" text2="Notre technologie s’appuie sur OpenAI et Mistral AI, deux des outils d'intelligence artificielle les plus puissants du marché."
                         color3="acid" text3="."
            />

            <AiLogos   />
            <Product
                title1=""
                title2="Avatar"
                text="Immergez-vous dans notre Marketplace exclusive et choisissez un avatar qui vous représente ou laissez libre cours à votre créativité en concevant un avatar sur mesure. Chaque avatar est une fenêtre vers votre identité numérique, ouvrant les portes à une personnalisation sans précédent."
                img="./images/ia/assistant.png"
                bg="/images/section/product/halo2.png"
                keyword="Marketplace | Personnalisation "
            />
            {isDesktop ? <Spacer height={200} />   : null}


            {isDesktop ?   <ProductReverse
                title1="inter"
                title2="action"
                text="Incorporez une IA conversationnelle de pointe, comparable à OpenAI ou Mistral AI, pour donner vie à votre avatar avec des options de personnalisation avancées : permettez-lui de s'exprimer avec une voix naturelle et fluide, sculptez une personnalité immersive et authentique, et habilitez-le à percevoir et interagir intuitivement avec son environnement, enrichissant ainsi l'expérience utilisateur."
                img="./images/ia/customisable.png"
                bg="/images/section/product/halo1.png"
                keyword="IA conversationnelle | Interaction intuitive"
            /> :  <Product
                title1="inter "
                title2="action"
                text="Incorporez une IA conversationnelle de pointe, comparable à OpenAI ou Mistral AI, pour donner vie à votre avatar avec des options de personnalisation avancées : permettez-lui de s'exprimer avec une voix naturelle et fluide, sculptez une personnalité immersive et authentique, et habilitez-le à percevoir et interagir intuitivement avec son environnement, enrichissant ainsi l'expérience utilisateur."
                img="./images/ia/customisable.png"
                bg="/images/section/product/halo1.png"
                keyword="IA conversationnelle | Interaction intuitive"
            />}
            {isDesktop ? <Spacer height={200} />   : null}
            <Product
                title1="DIFFUSION "
                title2="MUTLIMODALE"
                text="Intégrez votre avatar personnalisé avec aisance sur tous les supports display — ordinateurs, tablettes, smartphones, hologrammes, réalité augmentée (AR) et réalité virtuelle (VR) — pour une visibilité universelle et une interaction sans limites.  "
                img="./images/ia/hologrammes.png"
                bg="/images/section/product/halo2.png"
                keyword="Technologies immersives | Multiplateforme "
            />
            {isDesktop ? <Spacer height={200} />   : null}
            {/*<TripleImage
                sectionName={["la combinaison ","révolutionnaire" ]}
                image={["./images/ia/avatar.png", "./images/ia/conversationnelle.png", "./images/ia/hologramme.png"]}
                titre={["avatar 3d", "ia conversationnelle", "hologramme"]}
                description={["100% personnalisable", "votre assistant vocal", "une totale immersion"]}
            />*/}
        </div>
    );
};

