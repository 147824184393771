import React from 'react';
import {Presentation} from "../../../Section/Hero/Hero";
import {Description} from "../../../Section/Description/Description";
import {Helmet} from "react-helmet";
import {GoogleMap} from "../GoogleMap/GoogleMap";
import {ContactHero} from "../ContactHero/ContactHero";
import {ContactDatas} from "../ContactDatas/ContactDatas";
import {ContactForm} from "../ContactForm/ContactForm";
import {Remerciement} from "../Remerciement/Remerciement";



export const PageConfirmation = () => {


    return (
        <div>



            <Remerciement/>
            {/*
            <ContactHero img="./images/contact/map_cover.png" titre1="nous" titre2="contacter" description="On vous écoute"/>
            */}
            <ContactDatas/>


        </div>
    );
};

