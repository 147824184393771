import {useEffect, useRef} from "react";
import "./GoogleMap.css";

export const MyMapComponent = ({ center, zoom }) => {
    const ref = useRef();
    const mapRef = useRef(null); // Pour stocker l'instance de la carte
    const overlayRef = useRef(); // Référence pour le filtre (image)
    const styles = [
        { elementType: 'geometry', stylers: [{ color: '#212121' }] },
        { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#212121' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
        {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [{ color: '#757575' }]
        },
        {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9e9e9e' }]
        },
        {
            featureType: 'administrative.land_parcel',
            stylers: [{ visibility: 'off' }]
        },
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#bdbdbd' }]
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#757575' }]
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#181818' }]
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{  visibility: 'off'}]
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#1b1b1b' }]
        },
        {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [{ color: '#2c2c2c' }]
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#8a8a8a' }]
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: '#373737' }]
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#3c3c3c' }]
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{ color: '#4e4e4e' }]
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{ visibility: 'off' }]
        },
        {
            featureType: 'transit',
            elementType: 'labels.text.fill',
            stylers: [{  visibility: 'off' }]
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#000000' }]
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#3d3d3d' }]
        }
    ];


    useEffect(() => {
        const mapInstance = new window.google.maps.Map(ref.current, {
            center,
            zoom,
            disableDefaultUI: true,
            scrollwheel: false,
            gestureHandling: 'cooperative',
            styles: styles
        });


        mapRef.current = mapInstance;




        const orbisMarker = new window.google.maps.Marker({
            position: {lat: 48.82458842403480, lng: 2.2434065839459016},
            map: mapInstance,
            title: "Orbis Holographics",
            icon: "./images/contact/ping.png"
        });
        const infoContent = `
            <div style="font-weight: bold;">Orbis Holographics</div>
            <a href="tel:0188248582" style="color : black; text-decoration: none" >Numéro : +33 1 88 24 85 82</a>
            <div>Horaires : 9h00 - 18h00</div>
        `;
        const infoWindow = new window.google.maps.InfoWindow({
            content: infoContent
        });
        orbisMarker.addListener("click", () => {
            infoWindow.open(mapInstance, orbisMarker);
        });

        // Deuxième marqueur (STATION F)
        const stationFPosition = { lat: 48.8342, lng: 2.3852 }; // Coordonnées approximatives de STATION F
        const stationFMarker = new window.google.maps.Marker({
            position: stationFPosition,
            map: mapInstance,
            title: "STATION F",
            icon: "./images/contact/ping.png"
        });

        const stationFInfoContent = `
        <div style="font-weight: bold;">STATION F</div>
        <!--<div>5 PARVIS ALAN TURING, 75013 PARIS</div>-->
    `;

        const stationFInfoWindow = new window.google.maps.InfoWindow({
            content: stationFInfoContent
        });

        stationFMarker.addListener("click", () => {
            stationFInfoWindow.open(mapInstance, stationFMarker);
        });

    }, [center, zoom]);

    return (
        <div style={{ position: 'relative', width: '100%', height: '746px' }}>
            <div ref={ref} style={{ width: '100%', height: '100%' }} />
            <div className="map-overlay" />
        </div>
    );
};
