import React, {useEffect, useState} from 'react';
import "./AiLogos.css";
import {useMediaQuery} from "react-responsive";
export const AiLogos = () => {



    return (
        <div className="aiLogos">
            <div className="descriptionAiLogo">
                Franchissez le seuil d'une nouvelle dimension d'interaction numérique développée avec :
            </div>
            <div className="logos">
                <img src="images/ia/openailogo.png" alt="Logo OpenAI"/>
                <img src="images/ia/mistrallogo.png" alt="Logo Mistral"/>
            </div>
        </div>


    );
};
