import React from 'react';
import './Clients.css';
import { useMediaQuery } from 'react-responsive';

// No need to import the logos if they are in the public folder

export const Clients = () => {

    return (
        <div className="Clients">
            <h1 className="titre">
                <span className="bai">nos </span>
                <span className="druk">clients</span>
            </h1>
            <p className="description">Ils nous font confiance</p>

            <div className="logos-grid">
                {Array.from({ length: 24 }, (_, index) => (
                    <div key={index} className="logo-container">
                        <img className="logo-image" src={`/images/accueil/logos/logo${index}.svg`} alt={`Logo ${index}`} />
                    </div>
                ))}
            </div>

            {/* Omitted the rest of your component for brevity, but leave it as is in your code */}

            <img className="carre" src="/images/accueil/carreacidright.png" alt="" />
        </div>
    );
};
