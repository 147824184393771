import React, {useState, useEffect} from "react";
import './Header.css';
import './ButtonCross.css';
import './ButtonLines.css';
import {MenuBurger} from "./MenuBurger";
import {useMediaQuery} from 'react-responsive';

export const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [menuOpen]);

    const accueil = () => {
        window.location.href = '/';
    }

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);

    };

    const [isMenuHovered, setIsMenuHovered] = useState(false);


    const isDesktop = useMediaQuery({ query: '(min-width: 700px)' });


    return (
        <>

            <header className="header">
                <div className="nav-container">
                    <nav className="nav-links">
                        <div className="left">
                            <img onClick={accueil} src="images/header/logo_orbis.png" alt="logo" className="logo"/>

                        </div>


                        <div className="right">

                            {isDesktop ?
                                <>
                                    {/*<a className="classiqueButton" href="https://shop.orbis-prod.fr/">shop</a>*/}
                                    {/*<a className="classiqueButton" href="/contact">contact</a>*/}
                                    {/*<a className="telephoneButton" href="tel:0140921153">01 40 92 11 53</a>*/}
                                    <a className="telephoneButton" href="/contact">contact</a>
                                </>
                                : <>
                                </>}

                            <button onClick={handleMenuClick}
                                    className={` ${menuOpen ? 'ButtonCross' : 'ButtonLines'}`}>
                                <div className="line"></div>
                                <div className="line"></div>
                            </button>
                        </div>
                    </nav>
                </div>
            </header>


            {<MenuBurger isOpen={menuOpen} onClose={() => setMenuOpen(false)}/>}
        </>
    );
};
