import React, { useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { fetchProjects } from "../fetchProjects";
import './ProjectDetail.css';
import ArrowReturn from "./ArrowReturn"; // Assurez-vous d'avoir un fichier CSS pour styliser votre galerie


const ProjectDetail = () => {
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { projectId } = location.state || {};
    const [currentIndex, setCurrentIndex] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const loadProjects = async () => {
            const fetchedProjects = await fetchProjects();
            if (fetchedProjects) {
                setProjects(fetchedProjects);
                const index = fetchedProjects.findIndex(p => p.id_projet === projectId);
                setCurrentIndex(index);
                setProject(fetchedProjects[index]);
            } else {
                console.error("Aucun projet récupéré");
            }
        };

        if (projectId) {
            loadProjects();
        }
    }, [projectId]);

    const goToProject = (index) => {
        if (index < 0) {
            index = projects.length - 1; // Dernier projet si index est inférieur à 0
        } else if (index >= projects.length) {
            index = 0; // Premier projet si index est égal ou dépasse la longueur du tableau
        }
        setCurrentIndex(index);
        setProject(projects[index]);
        navigate('/projets', { state: { projectId: projects[index].id_projet } });
    };

    if (!project) {
        return <div>Chargement...</div>;
    }



    return (

        <>

            <div className="projectDetail">
                <div className="container">

                    <div className="arowReturn"  onClick={() => navigate('/realisations')} >
                        <ArrowReturn />
                        <span> Retour </span>
                    </div>


                    <div className="hero">
                        <div className="project-info">
                            <h2>{project.nom_du_client}</h2>
                            <p>{project.nom_du_projet}</p>
                            <p>{project.mots_cles}</p>
                        </div>
                        <img src={`https://dev.orbis-prod.fr/CASE-STUDIES/${project.image_presentation}`} alt={project.nom_du_projet} />

                    </div>


                    <div className="paragraphe">
                        <h1>Challenge</h1>
                        <p>{project.challenge}</p>

                        <h1>Concept</h1>
                        <p>{project.concept}</p>

                        <h1>Résutats</h1>
                        <p>{project.resultats}</p>
                    </div>


                    <div className="images">
                        <div className="image"><img src={`https://dev.orbis-prod.fr/CASE-STUDIES/${project.image1}`} alt={project.nom_du_projet} /></div>
                        <div className="image"><img src={`https://dev.orbis-prod.fr/CASE-STUDIES/${project.image2}`} alt={project.nom_du_projet} /></div>
                        <div className="image overlay">
                            <a href={project.lien_video}  target="_blank">
                            <img src={`https://dev.orbis-prod.fr/CASE-STUDIES/${project.image_presentation}`} alt={project.nom_du_projet} />

                            </a></div>
                        <div className="image"><img src={`https://dev.orbis-prod.fr/CASE-STUDIES/${project.image3}`} alt={project.nom_du_projet} /></div>
                        <div className="image"><img src={`https://dev.orbis-prod.fr/CASE-STUDIES/${project.image4}`} alt={project.nom_du_projet} /></div>
                    </div>



                    <div className="separateur"></div>
                    <div className="buttons">
                        <div className="arowReturn2" onClick={() => navigate('/realisations')} >
                            <span> Toutes nos réalisations  </span>
                            <ArrowReturn />
                        </div>
                        <div className="arrowNextPrevious">
                            <div  onClick={() => goToProject(currentIndex + 1)} >
                            <ArrowReturn  />
                            </div>

                            <div  onClick={() => goToProject(currentIndex - 1)} >
                                <ArrowReturn   isReversed={true}  />
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>

    );
};

export default ProjectDetail;
