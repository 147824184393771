import React from 'react';
import "./PageInteractivite.css";
import {Presentation} from "../../Section/Hero/Hero";
import {Description} from "../../Section/Description/Description";
import {Helmet} from "react-helmet";
import {Product} from "../../Section/Product/Product";
import {Spacer} from "../../Spacer/Spacer";
import {ProductReverse} from "../../Section/Product/ProductReverse";
import {TripleImage} from "../../Section/TripleImage/TripleImage";
import {useMediaQuery} from "react-responsive";
import {HeroVideo} from "../../Section/HeroVideo/HeroVideo";



export const PageInteractivite = () => {

    const description = "Vivez l'interactivité à un autre niveau chez Orbis : Interaction en temps réel avec des hologrammes. Découvrez comment nos hologrammes interactifs révolutionnent la communication et l'engagement, offrant des expériences immersives pour l'éducation, le marketing et les divertissements.";
    const schema = {
        "@context": "http://schema.org",
        "@type": "Service",
        "name": "Interaction d'hologrammes",
        "description": description,
        "url": "https://orbis-prod.fr/interactivite",
        "provider": {
            "@type": "Organization",
            "name": "Orbis Holographics"
        }
    };
    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const isMobileMedia = useMediaQuery({ query: '(min-width: 700px)' });

    const video = isMobileMedia ? "video/hero/INTERACTIVITE.mp4": "video/hero/INTERACTIVITE_MOBILE.mp4";
    return (
        <div>
            <Helmet>
                <title>Interactivité Révolutionnaire avec les Hologrammes Orbis</title>
                <meta name="description" content={description}/>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>


            <HeroVideo videoSrc={video} titre1="inter" titre2="activités" description="Eye tracking | Motion tracking | Voix | Etc."/>
            {/*<Presentation  img="images/section/presentation/interactivite.png" titre1="inter" titre2="activités" description="Eye tracking | Motion tracking | Voix | Etc."/>*/}
            <Description color1="white"  text1="Boostez vos communications internes & externes grâce à nos solutions phygitales. "
                         color2="grey" text2="Faites vivre à votre audience des expériences immersives, interactives, originales et inédites, sur vos événements et points de vente"
                         color3="acid" text3="."
            />

            {/*<TripleImage
                sectionName={["nos solutions de ","référence" ]}
                image={["./images/interactivite/interaction_proche.png", "./images/interactivite/interaction_eloignee.png", "./images/interactivite/interaction_tablette.png"]}
                titre={["Interaction proche", "Interaction éloignée", "Interaction tablette"]}
                description={["leap motion", "realsense", "tablette tactile"]}
            />*/}

            <Product
                title1="INTERACTION "
                title2="PROCHE"
                text="Nous développons des environnements virtuels 3D qui plongent les utilisateurs dans des mondes réalistes et interactifs. Nous veillons à ce que chaque élément, des textures aux effets d’éclairage, soit soigneusement conçu pour offrir une expérience utilisateur fluide et immersive."
                img="./images/interactivite/interaction_proche.png"
                bg="/images/section/product/halo2.png"
                keyword="Unreal Engine | Unity"
            />



            {isDesktop ?   <ProductReverse
                title1="INTERACTION "
                title2="ÉLOIGNÉE"
                text="Nous comprenons l’importance de créer des expériences 3D temps réel adaptées aux besoins spécifiques de votre projet. Notre équipe travaille en étroite collaboration avec vous pour personnaliser les fonctionnalités, les interactions et les environnements en fonction de vos objectifs et de votre vision créative."
                img="./images/interactivite/interaction_eloignee.png"
                bg="/images/section/product/halo1.png"
                keyword="Environnements et expériences sur mesure"
            /> :  <Product
                title1="INTERACTION "
                title2="ÉLOIGNÉE"
                text="Nous comprenons l’importance de créer des expériences 3D temps réel adaptées aux besoins spécifiques de votre projet. Notre équipe travaille en étroite collaboration avec vous pour personnaliser les fonctionnalités, les interactions et les environnements en fonction de vos objectifs et de votre vision créative."
                img="./images/interactivite/interaction_eloignee.png"
                bg="/images/section/product/halo1.png"
                keyword="Expériences sur mesure"
            />}



            <Product
                title1="INTERACTION "
                title2="TABLETTE"
                text=" Nous créons des systèmes de contrôle intuitifs et des scénarios interactifs qui encouragent les utilisateurs à explorer et à s’immerger dans votre univers virtuel. Via des capteurs sans fil tels que Leap Motion et des capteurs radar nous relions le monde réel au monde virtuel, offrant une expérience utilisateur encore plus immersive."
                img="./images/interactivite/interaction_tablette.png"
                bg="/images/section/product/halo2.png"
                keyword="Temps Réel | Exploration | Immersion"
            />




        </div>
    );
};

