import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './SliderMobile.css';

const image = [
    './images/ar/slider1.png',
    './images/ar/slider2.png',
    './images/ar/slider3.png',
    './images/ar/slider4.png',
    './images/ar/slider5.png',


];
export const SliderMobile = ({images = []}) => {
    const [index, setIndex] = useState(0);

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handleDragEnd = (event, info) => {
        if (info.offset.x > 10) {
            handlePrev();
        } else if (info.offset.x < -10) {
            handleNext();
        }
    };
    return (
        <div className="slider-container">
            <h1> <span className="bai">nos </span> <span  className="druk">éxpériences ar</span></h1>
            <p className="keyword">Expérience AR Made in Orbis</p>


            <div className="buttonLeft" onClick={handlePrev}></div>
            <div className="buttonRight" onClick={handleNext}></div>

            <motion.img
                src={images[(index - 1 + images.length) % images.length].src}
                className="prev-image"
            />

            <motion.img
                key={index}
                src={images[index].src}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.3 }}
                className="current-image"
                drag="x"
                onDragEnd={handleDragEnd}
            />
            <div className="image-info">
                <h3>{images[index].title}</h3>
                <p>{images[index].date}</p>
            </div>
            <motion.img
                src={images[(index + 1) % images.length].src}
                className="next-image"
            />

            <div className="gradient-left"></div>
            <div className="gradient-right"></div>
            <img className="image" src="/images/ar/light_mobile.png"></img>
        </div>
    );
};
