import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { MyMapComponent } from "./MyMapComponent";
import React from "react";
export const GoogleMap = () => {
    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <p>Chargement...</p>;
            case Status.FAILURE:
                return <p>Erreur lors du chargement de la carte</p>;
            case Status.SUCCESS:
                return <MyMapComponent center={{ lat: 48.82858842403480, lng: 2.232 }} zoom={15} />;
        }
    };

    return <Wrapper apiKey="AIzaSyCoGBWOeEL5uSEyuQArlapKU0b0pS_7fwo" render={render} />;
};
