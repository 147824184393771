import React from "react";
import "./ButtonSite.css";
import {useMediaQuery} from "react-responsive";

export const ButtonSite = ({logo, text, lien}) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const scale = {
        transform: 'scale(0.7)'
    };

    return (
        <a href={lien} className='buttonSite' style={isDesktop ? null : scale}>
            <img src={logo} alt='logo' className='buttonSite-logo' />
            <p className='buttonSite-text'>{text}</p>
        </a>
    );
};



