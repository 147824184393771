

export const fetchProjects = async () => {
    const url = 'https://docs.google.com/spreadsheets/d/1i1M0bu6-GV7OpETO9IpgWxwznk1M_em5lE690WB-mCs/gviz/tq?tqx=out:json&sheet=realisations';
    try {
        const response = await fetch(url);
        const text = await response.text();
        const jsonText = text.substr(text.indexOf('{'), text.lastIndexOf('}') - text.indexOf('{') + 1);
        const json = JSON.parse(jsonText);
        // Assurez-vous que chaque chemin retourne un tableau
        return json.table.rows.map(row => ({
            id_projet: row.c[0]?.v,
            nom_du_client: row.c[1]?.v,
            nom_du_projet: row.c[2]?.v,
            mots_cles: row.c[3]?.v,
            image_presentation: row.c[4]?.v,
            challenge: row.c[5]?.v,
            concept: row.c[6]?.v,
            resultats: row.c[7]?.v,
            image1: row.c[8]?.v,
            image2: row.c[9]?.v,
            image3: row.c[10]?.v,
            image4: row.c[11]?.v,
            lien_video: row.c[12]?.v,
        }))
            .filter(project => Object.values(project).every(value => value !== null && value !== undefined));
    } catch (error) {
        console.error('Erreur lors de la récupération des projets:', error);
        // Retourne un tableau vide en cas d'erreur
        return [];
    }
};
