import React, {useState, useEffect, useRef} from 'react';
import "./FirstLoad.css";

export const FirstLoad = ({ className }) => {

    window.scrollTo(0, 0);

    const words = ["holographic", "interactive", "augmented reality", "ia", "the new"];
    const [index, setIndex] = useState(0);
    const [word, setWord] = useState(words[0]);
    const wordRef = useRef(null);
    const containerRef = useRef(null);
    const [targetWidth, setTargetWidth] = useState(983);
    const [rotationDegree, setRotationDegree] = useState(0);
    const [rotationDegreeLogo, setRotationDegreeLogo] = useState(0);
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (words[index] === "the new") {
                clearInterval(interval);
                setIsFinished(true);
                return;
            }
            const nextIndex = (index + 1) % words.length;
            const nextWordWidth = calculateWordWidth(words[nextIndex]);
            setTargetWidth(nextWordWidth);
            setIndex(nextIndex);
            setRotationDegree(prevDegree => prevDegree + 90);
           setRotationDegreeLogo(prevDegree => prevDegree + 30);
        }, 1000);

        return () => clearInterval(interval);
    }, [index, words]);





    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.style.width = `${targetWidth}px`;
        }
    }, [targetWidth]);

    useEffect(() => {
        setWord(words[index]);
    }, [index, words]);



    const backgroundImage = {
        backgroundImage: `url(images/loader/roue.png)`,
        transform: `rotate(${rotationDegree}deg)`
    };

    const logoOrbis = {
        backgroundImage: `url(images/header/logo_orbis.png)`,
        transform: `rotate(${rotationDegreeLogo}deg)`
    };




    return (

        <div className={`FirstLoad ${className}`}>
            <div className="logo" style={logoOrbis}   ></div>
            <div className="container">
                <div className="bg" style={backgroundImage}   ></div>
                <h1 className="text1">we are</h1>
                <div className="animation-container">
                    <div className="word-container" ref={containerRef}>
                        <span ref={wordRef}>
                          {word}
                        </span>
                    </div>
                </div>
                <h1 className="text3">experience</h1>
                <h1 className="text4">makers</h1>

            </div>
        </div>
    );
};




function calculateWordWidth(word) {
    const tempElement = document.createElement('span');
    tempElement.style.fontSize = '80px';
    tempElement.style.fontFamily = 'Druk';
    tempElement.style.display = 'inline-block';
    tempElement.style.visibility = 'hidden';
    tempElement.textContent = word;
    document.body.appendChild(tempElement);
    const width = tempElement.offsetWidth;
    document.body.removeChild(tempElement);

    return (width * 1.3) + 100;
}
