import React from 'react';
import "./PageAR.css";
import {Presentation} from "../../Section/Hero/Hero";
import {Description} from "../../Section/Description/Description";
import {Helmet} from "react-helmet";
import {Product} from "../../Section/Product/Product";
import {ProductReverse} from "../../Section/Product/ProductReverse";
import {Spacer} from "../../Spacer/Spacer";
import Slider from "./Slider/Slider";
import {useMediaQuery} from "react-responsive";
import {Folder} from "../Hologramme/Folder/Folder";
import {SliderMobile} from "./Slider/SliderMobile";
import {ProductVideo} from "../../Section/ProductVideo/ProductVideo";
import {ProductVideoReverse} from "../../Section/ProductVideo/ProductVideoReverse";
import {HeroVideo} from "../../Section/HeroVideo/HeroVideo";




export const PageAR = () => {
    const description = "Découvrez Orbis AR - Pionnier de la Réalité Augmentée à Paris. Explorez nos solutions AR innovantes pour transformer votre entreprise. Technologie immersive pour marketing, formation et plus.";

        const schema = {
        "@context": "http://schema.org",
        "@type": "Service",
        "name": "Réalité augmentée",
        "description": description,
            "url": "https://orbis-prod.fr/ar",
            "provider": {
            "@type": "Organization",
            "name": "Orbis Holographics"
        }
    };

    const imagesSlider = [
        {
            src: './images/ar/slider5.png',
            title: 'thales',
            date: '2023'
        },
        {
            src: './images/ar/slider4.png',
            title: 'dior',
            date: '2022'
        },
        {
            src: './images/ar/slider2.png',
            title: 'youv dee',
            date: '2020'
        },
        {
            src: './images/ar/slider3.png',
            title: 'orlinski',
            date: '2020'
        },
        {
            src: './images/ar/slider1.png',
            title: 'Valentino',
            date: '  2020'
        },
        {
            src: './images/ar/slider4.png',
            title: 'dior',
            date: '2022'
        }
    ];
    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });

    const isMobileMedia = useMediaQuery({ query: '(min-width: 700px)' });
    const video = isMobileMedia ? "video/hero/AR.mp4": "video/hero/AR_MOBILE.mp4";
    return (
        <div>
            <Helmet>
                <title>Réalité Augmentée par Orbis - Expériences AR Immersives</title>
                <meta name="description" content={description} />
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>

            {/*<Presentation img="images/section/presentation/ar.png" titre1="réalité" titre2="augmentée" description="Instagram | Snapchat | Tiktok | WebAr | Appnative"/>*/}
            <HeroVideo videoSrc={video} titre1="réalité" titre2="augmentée" description="Instagram | Snapchat | Tiktok | WebAr | Appnative"/>
            <Description color1="acid"  text1="Nouvel outil incontournable du social content"
                         color2="grey" text2=", la réalité augmentée est en passe de devenir un acteur incontournable du monde de demain en ajoutant à notre monde réel des éléments virtuels"
                         color3="acid" text3="."
            />

            <ProductVideo
                title1="filtre "
                title2="ar"
                text="Explorez l'univers enrichi de la réalité augmentée avec nos filtres AR. Nos filtres sophistiqués transforment le banal en extraordinaire, permettant à votre contenu de captiver et d'engager le public de manière innovante et immersive. Exploitez la puissance de la réalité augmentée pour créer des expériences visuelles dynamiques et interactives qui résolvent, inspirent et émerveillent."
                video="./video/ar/AR_FILTER_600.mp4"

                keyword="Tiktok | Instagram | Snapchat | Facebook"
            />
            {isDesktop ? <Spacer height={200} />   : null}

            {isDesktop ?   <ProductVideoReverse
                title1="web "
                title2="ar"
                text="Plongez dans le monde captivant du Web AR, où le numérique rencontre la réalité physique pour offrir des expériences en réalité augmentée uniques. Avec la WebAR, vos clients peuvent découvrir en avant-première des produits en 3D directement depuis leur navigateur en scannant un QR code, interagir avec l'univers de votre marque, et bien plus encore. Explorez de nouvelles façons de captiver votre audience et de créer des connexions mémorables avec votre marque grâce à la magie du Web AR."
                video="./video/ar/WEB_AR_600_6.mp4"

                keyword="Navigateur | Expérience immersive | Innovation"
            /> :  <ProductVideo
                title1="web "
                title2="ar"
                text="Plongez dans le monde captivant du Web AR, où le numérique rencontre la réalité physique pour offrir des expériences en réalité augmentée uniques. Avec la WebAR, vos clients peuvent découvrir en avant-première des produits en 3D directement depuis leur navigateur en scannant un QR code, interagir avec l'univers de votre marque, et bien plus encore. Explorez de nouvelles façons de captiver votre audience et de créer des connexions mémorables avec votre marque grâce à la magie du Web AR."
                video="./video/ar/WEB_AR_600_6.mp4"

                keyword="Navigateur | Expérience immersive | Innovation"
            />}

            {isDesktop ? <Spacer height={200} />   : null}
            <ProductVideo
                title1="app "
                title2="native"
                text= "Nos applications natives sont spécialement conçues pour offrir des performances optimales sur des systèmes d'exploitation spécifiques. Elles promettent une réactivité, une fluidité et une performance inégalées. Chaque application que nous développons est minutieusement ajustée pour exploiter pleinement les capacités matérielles et logicielles de chaque appareil, garantissant ainsi une expérience utilisateur intuitive et performante."
                video="./video/ar/APP_NATIVE_600.mp4"
                keyword="iOS | Android | Mobile | Tablette"
            />
            {isDesktop ? <Spacer height={200} />   : null}

            {isDesktop ? <Slider images={imagesSlider}/>  : <SliderMobile images={imagesSlider}/>}
            {isDesktop ? <Spacer height={200} />   : null}
        </div>
    );
};

