import React, { useState, useEffect } from "react";
import "./ButtonSocial.css";

export const ButtonSocial = ({lien, platform, marginTop}) => {

    const [logo, setLogo] = useState({
        base: "",
        acid: "",
    });

    const batton ="images/button/batton.png";
    const battonacid ="images/button/battonacid.png";

    const handleMouseOver = () => {
        switch(platform) {
            case 'Facebook':
                setLogo({base: "images/button/facebook.png", acid: "images/button/facebookacid.png"});
                break;
            case 'Instagram':
                setLogo({base: "images/button/instagram.png", acid: "images/button/instagramacid.png"});
                break;
            case 'Linkedin':
                setLogo({base: "images/button/linkedin.png", acid: "images/button/linkedinacid.png"});
                break;
            case 'Youtube':
                setLogo({base: "images/button/youtube.png", acid: "images/button/youtubeacid.png"});
                break;
            default:
                break;
        }
    };

    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        handleMouseOver();
    }, [platform]);

    return (
        <a href={lien} className='buttonSocial' target="_blank" rel="noreferrer"
           style={{ marginTop: marginTop }}
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}
        >
            <div className="batton">
                <img src={batton} alt='logo baton' className={` ${isHovered ? 'fade-out' : 'fade-in'}`} />
                <img src={battonacid} alt='logo baton acid' className={` ${isHovered ? 'fade-in' : 'fade-out'}`} />
            </div>

            <div className="logo">
                <img src={logo.base} alt='logo base' className={` ${isHovered ? 'fade-out' : 'fade-in'}`} />
                <img src={logo.acid} alt='logo acid' className={` ${isHovered ? 'fade-in' : 'fade-out'}`} />
            </div>
            <p className='text'>{platform}</p>
        </a>
    );
};
