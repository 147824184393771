import React, { useEffect, useState } from 'react';
import { StaticRouter} from 'react-router-dom/server';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PageAccueil } from "./Component/Pages/Accueil/PageAccueil";
import { Header } from "./Component/Header/Header";
import { Footer } from "./Component/Footer/Footer";
import { PageStudio } from "./Component/Pages/Studio/PageStudio";
import { PageAR } from "./Component/Pages/AR/PageAR";
import { PageInteractivite } from "./Component/Pages/Interactivite/PageInteractivite";
import { PageIA } from "./Component/Pages/IA/PageIA";
import { PageRealisation } from "./Component/Pages/Realisation/PageRalisation";
import { PageContact } from "./Component/Pages/Contact/PageContact";
import { PageConfirmation } from "./Component/Pages/Contact/Confirmation/PageConfirmation";

import { BrowserRouter } from 'react-router-dom';
import { MainLoader } from "./Component/Loader/MainLoader/MainLoader";
import { FirstLoad } from "./Component/Loader/FirstLoad/FirstLoad";
import RouteChangeTracker from "./Component/Pages/Contact/RouteChangeTracker/RouteChangeTracker";
import ProjectDetail from "./Component/Pages/Realisation/ProjectDetail/ProjectDetail";
import { PageHologramme } from "./Component/Pages/Hologramme/PageHologramme";

const App = ({ context = {}, location = null }) => {
    const [loading, setLoading] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);
    const [firstVisit, setFirstVisit] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const lastVisit = localStorage.getItem('lastVisit');
            const today = new Date().toDateString();

            if (lastVisit !== today) {
                setFirstVisit(true);
                localStorage.setItem('lastVisit', today);
            }

            setTimeout(() => {
                setFadeOut(true);
                setTimeout(() => setLoading(false), 1000);
            }, 300);
        }
    }, []);

    const RouterComponent = location ? StaticRouter : BrowserRouter;
    const LoaderComponent = firstVisit ? FirstLoad : MainLoader;

    return (
        <div className="app-container">
            {loading && (
                <div className={`loader ${fadeOut ? 'fade-out' : ''}`}>
                    <LoaderComponent />
                </div>
            )}
            <div className="page-content">
                <RouterComponent location={location} context={context}>
                    <RouteChangeTracker />
                    <Header />
                    <Routes>
                        <Route path="*" element={<Navigate to="/accueil" replace />} />
                        <Route path="/accueil" element={<PageAccueil />} />
                        <Route path="/hologrammes" element={<PageHologramme />} />
                        <Route path="/studio" element={<PageStudio />} />
                        <Route path="/ar" element={<PageAR />} />
                        <Route path="/interactivite" element={<PageInteractivite />} />
                        <Route path="/ia" element={<PageIA />} />
                        <Route path="/realisations" element={<PageRealisation />} />
                        <Route path="/contact" element={<PageContact />} />
                        <Route path="/confirmation" element={<PageConfirmation />} />
                        <Route path="/projets" element={<ProjectDetail />} />
                        {/* Supprimez le commentaire ci-dessous si vous voulez activer cette route */}
                        {/* <Route path="/loader" element={<FirstLoad />} /> */}
                    </Routes>
                    <Footer />
                </RouterComponent>
            </div>
        </div>
    );
};

export default App;
