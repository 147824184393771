import React, { useState, useEffect } from 'react';
import './Gallery.css'; // Assurez-vous d'avoir un fichier CSS pour styliser votre galerie
import { useNavigate } from 'react-router-dom';
import { fetchProjects } from "../fetchProjects"; // Assurez-vous que le chemin d'importation est correct

const Gallery = () => {
    const [projects, setProjects] = useState([]); // Stocke les projets récupérés
    const navigate = useNavigate(); // Utilisez le hook useNavigate pour la navigation

    const openProject = (id) => {
        navigate('/projets', { state: { projectId: id } });
    };


    useEffect(() => {
        const loadProjects = async () => {
            const fetchedProjects = await fetchProjects();
            if (fetchedProjects) {
                // Filtrer les projets pour ne garder que ceux qui ont toutes les informations nécessaires
                const completeProjects = fetchedProjects.filter(project => {
                    // Remplacez 'project.nom_du_client', 'project.nom_du_projet', et 'project.mots_cles' avec les vrais noms des propriétés que vous attendez
                    return project.id_projet && project.nom_du_client && project.nom_du_projet && project.mots_cles && project.image_presentation;
                });
                setProjects(completeProjects);
            } else {
                console.error("Aucun projet récupéré");
            }
        };

        loadProjects();
    }, []);

    return (
        <div className="gallery">
            {projects.map((project) => (
                <div key={project.id_projet} className="project" onClick={() => openProject(project.id_projet)}>
                    <img src={`https://dev.orbis-prod.fr/CASE-STUDIES/${project.image_presentation}`} alt={project.nom_du_projet} />
                    <div className="project-info">
                        <h2>{project.nom_du_client}</h2>
                        <p>{project.nom_du_projet}</p>
                        <p>{project.mots_cles}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Gallery;
