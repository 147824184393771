import React from 'react';
import "./PageStudio.css";
import {Carousel} from "./Carousel/Carousel";
import {Presentation} from "../../Section/Hero/Hero";
import {Description} from "../../Section/Description/Description";
import {Helmet} from "react-helmet";
import {Spacer} from "../../Spacer/Spacer";
import {useMediaQuery} from "react-responsive";
import {Product} from "../../Section/Product/Product";
import {HeroVideo} from "../../Section/HeroVideo/HeroVideo";
import {ProductVideo} from "../../Section/ProductVideo/ProductVideo";



export const PageStudio = () => {

    const description = "Plongez dans le monde de la création 3D avec Orbis Studio. Découvrez nos services de modélisation, animation et visualisation 3D à Paris. Donnez vie à vos idées avec des rendus de haute qualité et des solutions 3D personnalisées.";
    const schema = {
        "@context": "http://schema.org",
        "@type": "Service",
        "name": "Studio 3D",
        "description": description,
        "url": "https://orbis-prod.fr/studio",
        "provider": {
            "@type": "Organization",
            "name": "Orbis Holographics"
        }
    };

    const media1 = { id: 1,title1:"HOLOGRAMME", title2:"", video: "video/studio/HOLOGRAMME.mp4",  description : "Innovation | Personnalisation | Technologie",  text: "Nos experts des technologies holographiques travaillent sans relâche pour vous offrir des contenus holographiques à la pointe de l'innovation et de la technologie. Nos experts en hologrammes sont prêts à donner vie à l'animation la plus adaptée à vos besoins et à vos envies, quel que soit le support que vous choisissez."};
    const media2 = { id: 2,title1:"VR", title2:"",  video: "video/studio/VR.mp4", description : "Immersive  | Avant-gardiste | Expérience ", text: "La réalité virtuelle se taille peu à peu une place dans la communication digitale des marques comme une technologie immersive et avant-gardiste. En plongeant l'utilisateur au cœur d'une expérience inédite, en totale harmonie avec l'univers de la marque, la réalité virtuelle laisse une empreinte mémorable et vous permet d'ancre l'essence même de votre marque dans l'esprit de vos spectateurs."  };
    const media3 = { id: 3,title1:"ANAMORPHOSE", title2:"",  video: "video/studio/ANAMORPHOSE.mp4", description : "Expérience spectateur | Immersive ",  text: "Issue initialement de la peinture, l'anamorphose trouve désormais sa place dans l'univers numérique en tant qu'expérience à part entière pour les spectateurs. Que ce soit à petite ou à grande échelle, elle donne vie à l'univers de vos produits et de votre marque d'une manière unique, offrant ainsi une forme de communication innovante et immersive."};
    const media4 = { id: 4,title1:"FOOH", title2:"",  video: "video/studio/FOOH.mp4", description : "Réalité augmentée | Créativité infinie ", text: "Le Fake Out Of Home révolutionne le street marketing et redéfinit le paysage publicitaire avec l'utilisation de réalité augmentée, d'effets spéciaux et d'images générées par ordinateur. Les grandes marques embrassent cette tendance car elle captive et engage les audiences de manière plus impactante et conversationnelle. De plus, elle offre l'avantage d'être moins coûteuse qu'une opération réelle." };
    const media5 = { id: 5,title1:"MAPPING", title2:"",  video: "video/studio/MAPPING.mp4", description : "Illusions dynamiques | Textures immersives ", text: "Le mapping offre la possibilité de donner vie à vos produits ou à votre environnement, que ce soit en créant des illusions pour dynamiser ou en ajoutant des textures pour plonger votre audience dans l'univers de votre marque. Cette technologie transforme votre esthétique visuelle en un outil puissant pour immerger vos spectateurs dans votre univers de marque." };
    const media6 = { id: 6,title1:"PRODUCTION AUDIOVISUELLE", title2:" ",  video: "video/studio/PROD_AUDIOVISUELLE.mp4", description : "Clips vidéo | Conception web | Branding ", text: "Grâce à notre solide expérience dans le domaine de l'audiovisuel, nous offrons également une vaste gamme de services liés à la production audiovisuelle classique : clips vidéo, montage, séances photo, conception web, branding, contenu social, et bien plus encore." };

    const isDesktop = useMediaQuery({ query: '(min-width: 1440px)' });
    const isMobileMedia= useMediaQuery({ query: '(min-width: 700px)' });
    const video = isMobileMedia ? "video/hero/STUDIO.mp4": "video/hero/STUDIO_MOBILE.mp4";
    const StudioProductMobile = () => {
        return (
            <>

            <ProductVideo title1={media1.title1} title2={media1.title2} text={media1.text} keyword={media1.description} video={media1.video} />
            <ProductVideo title1={media2.title1} title2={media2.title2} text={media2.text} keyword={media2.description} video={media2.video} />
            <ProductVideo title1={media3.title1} title2={media3.title2} text={media3.text} keyword={media3.description} video={media3.video} />
            <ProductVideo title1={media4.title1} title2={media4.title2} text={media4.text} keyword={media4.description} video={media4.video} />
            <ProductVideo title1={media5.title1} title2={media5.title2} text={media5.text} keyword={media5.description} video={media5.video} />
            <ProductVideo title1={media6.title1} title2={media6.title2} text={media6.text} keyword={media6.description} video={media6.video} />
            </>
        );
    }
    return (
        <div>

            <Helmet>
                <title>Orbis Studio 3D - Création et Animation 3D à Paris</title>
                <meta name="description" content={description}/>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>

            <HeroVideo videoSrc={video} titre1="notre" titre2="studio" description="En interne"/>
            {/*<Presentation img="images/section/presentation/studio.png" titre1="notre" titre2="studio" description="En interne"/>*/}
           

            <Description color1="white"  text1="Découvrez notre studio de création et de production pour"
                         color2="acid" text2="donner vie à vos histoires."
                         color3="grey" text3="Des hologrammes 3d au clip vidéo en passant par les communications digitale et print, la polyvalence de nos équipes est au service de votre proJEt. "
            />

            {isDesktop ? <Carousel media1={media1} media2={media2} media3={media3} media4={media4} media5={media5} media6={media6} />: <StudioProductMobile />}


            <Spacer height={200} />
        </div>
    );
};

